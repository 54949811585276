import axios from "axios";
import { initializeApp } from "firebase/app";
import { getStorage, ref, getDownloadURL, connectStorageEmulator } from "firebase/storage";

let firebaseApp, storage

class FirebaseService {



    init() {
        const firebaseConfig = {
            apiKey: 'AIzaSyB_Phqtym8PgxJRlw3rBZsPq5wxb-TE2wU',
            authDomain: 'v-art-marketplace.firebaseapp.com',
            databaseURL: 'https://v-art-marketplace-default-rtdb.firebaseio.com',
            storageBucket: 'v-art-marketplace.appspot.com'
        };
        firebaseApp = initializeApp(firebaseConfig);
        storage = getStorage(firebaseApp);
    }

    async getBlob(folder, id) {
        let blobResolve, blobReject
        let blobPromice = new Promise((resolve, reject) => {
            blobResolve = resolve
            blobReject = reject
        })
        const storage = getStorage();
        // const gs = 'gs://v-art-marketplace.appspot.com/artwork-preview/' + url
        let blobResult
            // const gs = "gs://v-art-marketplace.appspot.com/artwork-preview/a9800f30-ccbd-11ec-a251-fb3c20b16488"
            // console.log('Firebase')
            // console.log(storage)
            // const gsReference = ref(storage, gs).then(resp => {
            // console.log(resp)
            // });
            // console.log(gsReference)
            // console.log('trying load ' + url)
            // const urlToDownload = await getDownloadURL(ref(storage, 'artwork-preview/' + url))
        const urlToDownload = await getDownloadURL(ref(storage, folder + '/' + id))
            // const urlToDownload = await getDownloadURL(ref(storage, url))
            // console.log('url to download')
            // console.log(urlToDownload)
        const response = await axios.get(urlToDownload, { responseType: 'blob' })
        blobResolve(response.data)
        await blobPromice
        return blobPromice

        // getDownloadURL(ref(storage, 'artwork-preview/' + url)).then((url) => {
        //     axios.get(url, { responseType: 'blob' })
        //         .then(resp => {
        //             console.log('resp')
        //             console.log(resp)
        //         })
        // })
    }
}

// const xhr = new XMLHttpRequest();
// xhr.responseType = 'blob';
//         // xhr.onload = (event) => {
//         //     const blob = xhr.response;
//         //     // console.log('blob')
//         //     // console.log(blob)
//         //     blobResult = blob

//     // };
//     // xhr.open('GET', url);
//     // xhr.send();

//     // Or inserted into an <img> element
//     const img = document.getElementById('myimg');
//     img.setAttribute('src', url);
//     // console.log(url)
// })
// .catch((error) => {
//     // Handle any errors
// });



// a9800f30 - ccbd - 11e c - a251 - fb3c20b16488


export default new FirebaseService()