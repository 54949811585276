import store from '../store'
import axios from 'axios';
import {
    Connector,
    InjectedWeb3ConnectionProvider,
    //   IConnectorStateProvider,
} from "@rarible/connector";

// import Web3 from "web3";
// import { Web3Ethereum } from "@rarible/web3-ethereum";
// import { EthereumWallet } from "@rarible/sdk-wallet";
import { WalletConnectConnectionProvider } from "@rarible/connector-walletconnect";
import { mapEthereumWallet } from "@rarible/connector-helper";
import { createRaribleSdk } from "@rarible/sdk";
// import { AssetType } from "@rarible/api-client";


const itemUrl = 'https://api-staging.rarible.org/v0.1/items/'


class RaribleService {

    isConnected() {
        let result = false
        const connector = store.getters["pay/CONNECTOR"]
        if (connector) {
            connector.connection.subscribe((connection) => {
                if (connection.status == "connected") {
                    result = true
                }
            })
        }
        return result;
    }

    async connectMetamask() {
        const environments = store.getters["ENVIRONMENTS"];
        var connectedResolve, connectedReject;
        var connected = new Promise(function(resolve, reject) {
            connectedResolve = resolve;
            connectedReject = reject;
        });

        const provider = new InjectedWeb3ConnectionProvider();
        const wallet = mapEthereumWallet(provider);
        const walletConnectProvider = new WalletConnectConnectionProvider({
            rpc: {
                1: "https://node-mainnet.rarible.com",
                4: "https://node-rinkeby.rarible.com",
            },
            chainId: environments.find(
                (el) => el.name == process.env.VUE_APP_MODE
            ).chainId,
        });
        const walletConnect = mapEthereumWallet(walletConnectProvider);
        const connector = Connector.create([wallet, walletConnect]);
        store.commit("CONNECTOR", connector);

        connector.connection.subscribe(
            (connection) => {
                if (connection.error) {
                    connectedReject(connection.error)
                }
                if (connection.status == "connected") {
                    connectedResolve();
                }

            }
        );
        const options = await connector.getOptions();
        if (options[0].option === 'Metamask') {
            await connector.connect(options[0]);
        } else {
            connectedReject({ "code": "777", "message": "Please install metamask first" })
        }
        await connected;
    }

    async getBalance() {
        var connectedResolve, connectedReject;
        var connected = new Promise(function(resolve, reject) {
            connectedResolve = resolve;
            connectedReject = reject;
        });
        const environments = store.getters["pay/ENVIRONMENTS"];
        const connector = store.getters["pay/CONNECTOR"]
        let b




        connector.connection.subscribe(async(connection) => {
            if (connection.status == "connected") {
                const sdk = createRaribleSdk(
                    connection.connection.wallet,
                    environments.find((el) => el.name == process.env.VUE_APP_MODE)
                    .raribleEnv
                );
                const balance = await sdk.balances.getBalance(
                    "ETHEREUM:" + connection.connection.address, { "@type": "ETH" }
                )
                b = balance
                connectedResolve();
            }

        })
        await connected;
        return b
    }

    async getItem(itemId) {
        return axios.get(
            itemUrl + itemId
        ).catch(error => {
            throw { code: "771" }
        })
    }

    async buyToken(orderId) {
        var buyResolve, buyReject;
        var bought = new Promise(function(resolve, reject) {
            buyResolve = resolve;
            buyReject = reject;
        });
        const environments = store.getters["pay/ENVIRONMENTS"];
        const connector = store.getters["pay/CONNECTOR"]
        connector.connection.subscribe(
            async(connection) => {
                if (connection.status == "connected") {
                    const sdk = createRaribleSdk(
                        connection.connection.wallet,
                        environments.find((el) => el.name == process.env.VUE_APP_MODE)
                        .raribleEnv
                    );
                    try {
                        const { submit, maxAmount } = await sdk.order.buy({ orderId });
                        const tx = await submit({
                            amount: maxAmount,
                        });
                        tx.transaction.receipt.then((r) => {
                                buyResolve(tx)
                            })
                            .catch(err => {
                                if (err.message.startsWith('Transaction has been reverted by the EVM')) {
                                    buyReject({ "code": "772" })
                                } else if (err.message.startsWith('MetaMask Tx Signature: User denied transaction signature')) {
                                    buyReject({ "code": "774" })
                                } else {
                                    buyReject(err.message)
                                }
                            })
                    } catch (err) {
                        if (err.message.startsWith('Transaction has been reverted by the EVM')) {
                            buyReject({ "code": "772" })
                        } else if (err.message.startsWith('MetaMask Tx Signature: User denied transaction signature')) {
                            buyReject({ "code": "774" })
                        } else {
                            buyReject(err.message)
                        }
                        // console.log('catch')
                        // console.log(err)
                        // console.log(err.message)
                        // buyReject(err.message)
                    }
                }
            })
        await bought;
        return bought;
    }

}

export default new RaribleService()