import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
// import VueRouter from 'vue-router'
import NearBuy from '../pages/NearBuy'
import NearMint from '../pages/NearMint'
import NearAddWallet from '../pages/NearAddWallet'
import NearList from '../pages/NearList'
import NearUnlist from '../pages/NearUnlist'
import NearBurn from '../pages/NearBurn'
import EthBurn from '../pages/EthBurn'
import EthList from '../pages/EthList'
import EthBuyWs from '../pages/EthBuyWs'
import EthBuyConfirm from '../pages/EthBuyConfirm'

import EthMint from '../pages/EthMint'
const routes = [
    {
        path: '/near-buy',
        name: 'near-buy',
        component: NearBuy
    },
    {
        path: '/near-mint',
        name: 'near-mint',
        component: NearMint
    },
    {
        path: '/near-add-wallet',
        name: 'near-add-wallet',
        component: NearAddWallet
    },
    {
        path: '/near-list',
        name: 'near-list',
        component: NearList
    },
    {
        path: '/near-unlist',
        name: 'near-unlist',
        component: NearUnlist
    },
    {
        path: '/near-burn',
        name: 'near-burn',
        component: NearBurn
    },
    {
        path: '/eth-mint',
        name: 'eth-mint',
        component: EthMint
    },
    {
        path: '/eth-burn',
        name: 'eth-burn',
        component: EthBurn
    },
    {
        path: '/eth-list',
        name: 'eth-list',
        component: EthList
    },
    {
        path: '/eth-buy-ws',
        name: 'eth-buy-ws',
        component: EthBuyWs
    },
    {
        path: '/eth-buy-confirm',
        name: 'eth-buy-confirm',
        component: EthBuyConfirm
    },
   
]

const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_BASE_URL),
    // history: createWebHashHistory(process.env.VUE_APP_BASE_URL),
    routes
})

export default router