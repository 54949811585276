const adminId = process.env.VUE_APP_ADMIN_ID
const nftContractId = process.env.VUE_APP_NFT_CONTRACT_ID
const marketContractId = process.env.VUE_APP_MARKET_CONTRACT_ID
const net = process.env.VUE_APP_NET

import * as nearAPI from "near-api-js";

const { keyStores, connect, WalletConnection } = nearAPI;
const { utils } = nearAPI;
const config = {
    networkId: net,
    keyStore: new keyStores.BrowserLocalStorageKeyStore(),
    nodeUrl: "https://rpc." + net + ".near.org",
    walletUrl: "https://wallet." + net + ".near.org",
    helperUrl: "https://helper." + net + ".near.org",
    explorerUrl: "https://explorer." + net + ".near.org",
}

let near, wallet, account, contract


class NearApi {

    async init() {
        // console.log('ADMIN ID: ' + adminId)
        // console.log('NFT CONTRACT ID: ' + nftContractId)
        // console.log('MARKET CONTRACT ID: ' + marketContractId)
        // console.log('NET: ' + net)
        near = await connect(config)
        wallet = new WalletConnection(near)


    }

    isSignedIn() {
        return wallet.isSignedIn();
    }

    signInMarket() {
        console.log(wallet);
        console.log(account);
        console.log(marketContractId);
        const signIn = () => {
            wallet.requestSignIn(
                marketContractId, // contract requesting access
            );
        };
        console.log('kajsdkajsbdkasb');
        signIn()
        // .then(resp => { console.log(resp) })
        // .catch(err => { console.log(err) })

    }

    signOut() {
        wallet.signOut()
    }

    async getAccount() {
        return await wallet.getAccountId();
    }

    loadMarketContract() {
        const walletAccountObj = wallet.account();
        return new nearAPI.Contract(
            walletAccountObj, // the account object that is connecting
            marketContractId, {
            // name of contract you're connecting to
            viewMethods: ["get_nft_price"], // view methods do not change state but usually return a value
            changeMethods: ["buy", "remove_from_market"], // change methods modify state
            sender: walletAccountObj, // account object to initialize and sign transactions.
        }
        );
    }

    loadNftContract() {
        const walletAccountObj = wallet.account();
        return new nearAPI.Contract(
            walletAccountObj, // the account object that is connecting
            nftContractId, {
            // name of contract you're connecting to
            viewMethods: [""], // view methods do not change state but usually return a value
            changeMethods: ["nft_mint", "nft_approve", "nft_burn", "nft_approve_initial"], // change methods modify state
            sender: walletAccountObj, // account object to initialize and sign transactions.
        }
        );
    }

    async getNft(tokenId) {

    }

    async getNftPrice(tokenId) {
        const marketContract = this.loadMarketContract()
        return marketContract.get_nft_price({
            token_uid: nftContractId + ":" + tokenId
        })
    }


    async buyNft(metadata) {
        const price = await this.getNftPrice(metadata.token_id)
        let marketContract
        let boughtResolve, boughtReject
        let bought = new Promise((resolve, reject) => {
            boughtResolve = resolve
            boughtReject = reject

        })
        try {
            marketContract = this.loadMarketContract()
        } catch (err) {
            boughtReject(err)
        }
        await marketContract.buy({
            nft_contract_id: nftContractId,
            token_id: metadata.token_id,
        },
            metadata.gas,
            price.toLocaleString().replace(/\s/g, '')
        )
            .then(resp => {
                console.log('resp')
                console.log(resp)
                boughtResolve(resp)
            })
            .catch(err => {
                console.log('err')
                console.log(err)
                boughtReject(err)
            })
        await bought
        return bought
    }

    async unlist(tokenId) {
        let marketContract
        let unlistResolve, unlistReject
        let unlisted = new Promise((resolve, reject) => {
            unlistResolve = resolve
            unlistReject = reject

        })


        try {
            const marketContract = this.loadMarketContract()
            const userAccount = await this.getAccount()
            const metadata = { nft_contract_id: nftContractId, token_id: tokenId }
            console.log(metadata);
            // unlistResolve(metadata)
            await marketContract.remove_from_market(
                metadata,
                "300000000000000",
                // mintData.gas, // attached GAS (optional)
                // "8830000000000000000000" // attached deposit in yoctoNEAR (optional)
                // userAccount
            )
                .then(resp => {
                    console.log('resp');
                    console.log(resp);
                    unlistResolve(resp)
                })
            // .catch(err => { 
            //     console.log('err');
            //     console.log(err);
            //     // throw new Error('oops')
            //     // unlistReject(err)
            //     unlistReject(new Error('Ooopes'))
            //  })

        }
        catch (err) {
            unlistReject(err)
        }
        await unlisted
        return unlisted
    }

    async burn(tokenId) {
        let marketContract
        let burnResolve, burnReject
        let burned = new Promise((resolve, reject) => {
            burnResolve = resolve
            burnReject = reject

        })
        try {
            const nftContract = this.loadNftContract()
            const userAccount = await this.getAccount()
            const metadata = { token_id: tokenId }
            console.log(metadata);
            const yn = utils.format.parseNearAmount("1");
            console.log(yn);
            // unlistResolve(metadata)
            await nftContract.nft_burn(

                // metadata,

                // '1',
                // "1000000000000000000000000"
                {

                // // //     meta: 'asdasda',
                
                args: {
                    token_id: metadata.token_id
                },
                // // deposit: yn
                amount: 1
  
                 }
                    // metadata,
                //  "1000000000000000000000000",
                 // mintData.gas, // attached GAS (optional)
                //  yn
                // "300000000000000",
                // mintData.gas, // attached GAS (optional)
                // "8830000000000000000000"
                // "1000000000000000000000000",
                // "300000000000000"
                // mintData.gas, // attached GAS (optional)
                // "8830000000000000000000" // attached deposit in yoctoNEAR (optional)
                // userAccount
            )
                .then(resp => {
                    console.log('resp');
                    console.log(resp);
                    burnResolve(resp)
                })
            // .catch(err => { 
            //     console.log('err');
            //     console.log(err);
            //     // throw new Error('oops')
            //     // unlistReject(err)
            //     unlistReject(new Error('Ooopes'))


        }
        catch (err) {
            burnReject(err)
        }
        await burned
        return burned
    }

    async listNft(metadata) {
        let marketContract
        let listResolve, listReject
        let listed = new Promise((resolve, reject) => {
            listResolve = resolve
            listReject = reject
        })
        try {
            const nftContract = this.loadNftContract()
            const userAccount = await this.getAccount()
            metadata.account_id = marketContractId
            console.log(metadata)
            await nftContract.nft_approve(
                metadata,
                "300000000000000",
                // mintData.gas, // attached GAS (optional)
                "8830000000000000000000" // attached deposit in yoctoNEAR (optional)
                // ,userAccount
            )
                .then(resp => { listResolve(resp) })
                .catch(err => { listReject(err) })
            await listed
            return listed
        } catch (err) {
            listReject(err)
        }
    }

    async listNftEnglishAuction(metadata){
        let marketContract
        let listResolve, listReject
        let listed = new Promise((resolve, reject) => {
            listResolve = resolve
            listReject = reject
        })
        try {
            const nftContract = this.loadNftContract()
            const userAccount = await this.getAccount()
            metadata.account_id = marketContractId
            console.log(metadata)
            await nftContract.nft_approve_initial(
                metadata,
                "300000000000000",
                // mintData.gas, // attached GAS (optional)
                "8830000000000000000000" // attached deposit in yoctoNEAR (optional)
                // ,userAccount
            )
                .then(resp => { listResolve(resp) })
                .catch(err => { listReject(err) })
            await listed
            return listed
        } catch (err) {
            listReject(err)
        }
    }
    // near call $NFT_CONTRACT_ID nft_approve '{"token_id": "'$TOKEN_ID'", "account_id": "'$MARKET_CONTRACT_ID'", "msg": 
    //   "{\"sale_conditions\": {\"price\":\"2000000000000000000000000\"}}"
    //   }' --deposit 0.00035 --gas 300000000000000 --accountId $NFT_USER



    //     loadContract(account_id) {
    //         const account = this.getAccount(account_id)
    //         contract = new nearAPI.Contract(
    //             account, // the account object that is connecting
    //             nftContractId, {
    //                 // name of contract you're connecting to
    //                 viewMethods: ["get_nft", "get_nfts"], // view methods do not change state but usually return a value
    //                 changeMethods: ["nft_mint", "nft_approve"], // change methods modify state
    //                 sender: account, // account object to initialize and sign transactions.
    //             }
    //         )
    //     }

    //     loadMarketContract(account_id) {
    //         const account = this.getAccount(account_id)
    //         contract = new nearAPI.Contract(
    //             account, // the account object that is connecting
    //             nftContractId, {
    //                 // name of contract you're connecting to
    //                 viewMethods: ["get_nft", "get_nfts"], // view methods do not change state but usually return a value
    //                 changeMethods: ["nft_mint", "nft_approve"], // change methods modify state
    //                 sender: account, // account object to initialize and sign transactions.
    //             }
    //         )
    //     }

    //     getNet() { return net }
    getNftContractId() { return nftContractId }
    //     getMarketContractId() { return marketContractId }
    //     getAdminId() { return adminId }

    //     isSignedIn() {
    //         if (wallet.isSignedIn()) {
    //             const account = this.getAccount()
    //             if (!(account.accountId == this.getAdminId())) {
    //                 this.signOut()
    //                 return false
    //             }
    //         }
    //         return wallet.isSignedIn()
    //     }

    //     signIn() {
    //         const signIn = () => { wallet.requestSignIn(nftContractId) }
    //         signIn()
    //     }

    //     getAccount() {
    //         account = wallet.account()
    //         return account;
    //     }

    //     signOut() {
    //         wallet.signOut()
    //     }

    async mintToken(mintData) {
        let nftContract
        let accId
        let mintResolve, mintReject
        let minted = new Promise((resolve, reject) => {
            mintResolve = resolve
            mintReject = reject

        })
        try {
            nftContract = this.loadNftContract()
            // console.log('nftContract')
            // console.log(nftContract)
            accId = nftContract.account.accountId
        } catch (err) {
            mintReject(err)
        }
        console.log('mintData')
        console.log(mintData)
        console.log('accId')
        console.log(accId)


        const tst = {
            token_id: mintData.token_id,
            token_owner_id: accId,
            token_metadata: mintData.token_metadata,
            token_royalties: mintData.token_royalties,
        }

        console.log(tst);
        await nftContract.nft_mint({
            token_id: mintData.token_id,
            token_owner_id: accId,
            token_metadata: mintData.token_metadata,
            token_royalties: mintData.token_royalties,
        },
            "300000000000000",
            // mintData.gas, // attached GAS (optional)
            // "8830000000000000000000" // attached deposit in yoctoNEAR (optional)
            "113800000000000000000000"
        )
            .then(resp => { mintResolve(resp) })
            .catch(err => { mintReject(err) })
        await minted
        return minted
    }

    async sellToken(sellData) {
        let contract
        let accId
        let soldResolve, soldReject
        let sold = new Promise((resolve, reject) => {
            soldResolve = resolve
            soldReject = reject

        })
        try {
            contract = this.loadNftContract()
            console.log('contract')
            console.log(contract)
            accId = contract.account.accountId
        } catch (err) {
            soldReject(err)
        }

        //         ```
        // near call $NFT_CONTRACT_ID nft_approve '{"token_id": "'$TOKEN_ID'", "account_id": "'$MARKET_CONTRACT_ID'", "msg": 
        //   "{\"sale_conditions\": {\"price\":\"1000000000000000000000000\", \"initial_payments\": {\"'$ARTIST_ID'\": 80, \"'$ADMIN_ID'\": 20}}}"
        //   }' --deposit 0.00035 --gas 300000000000000 --accountId $ADMIN_ID
        // ```
        // token_id: this.form.id,
        //         account_id: "",
        //         msg: {
        //           sale_conditions: {
        //             price: this.form.price,
        //             initial_payments: this.packArtists(),
        //           },
        //         },

        sellData.account_id = marketContractId
        console.log(sellData)

        await contract.nft_approve(
            sellData,
            "300000000000000",
            // mintData.gas, // attached GAS (optional)
            "8830000000000000000000" // attached deposit in yoctoNEAR (optional)
        )
            .then(resp => { soldResolve(resp) })
            .catch(err => { soldReject(err) })
        await sold
        return sold
    }

    //     async buyNft(tokenId){
    //         let accId
    //         let boughtResolve, boughtReject
    //         let bought = new Promise((resolve, reject) => {
    //             boughtResolve = resolve
    //             boughtReject = reject

    //         })
    //         try {
    //             this.loadContract()
    //             accId = account.accountId
    //         } catch (err) {
    //             boughtReject(err)
    //         }
    //         await contract.nft_mint({
    //                     token_id: mintData.token_id,
    //                     token_owner_id: accId,
    //                     token_metadata: mintData.token_metadata,
    //                     token_royalties: mintData.token_royalties,
    //                 },
    //                 mintData.gas, // attached GAS (optional)
    //                 mintData.deposit // attached deposit in yoctoNEAR (optional)
    //             )
    //             .then(resp => { mintResolve(resp) })
    //             .catch(err => { mintReject(err) })
    //         await minted
    //         return minted
    //         near call $MARKET_CONTRACT_ID buy '{"nft_contract_id": "'$NFT_CONTRACT_ID'", 
    //   "token_id": "'$TOKEN_ID'"}' --deposit 1.15 --gas 300000000000000 --accountId $NFT_USER
    //     }




}

export default new NearApi()