<template>
  <div class="near-buy-page">
    <div class="near-buy-page__container container">
      <div v-if="connected">
        <div class="near-buy-page__logo">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="85"
            height="102"
            viewBox="0 0 85 102"
            fill="none"
          >
            <path
              d="M23 2H42.5C53.2696 2 62 10.7304 62 21.5V50"
              stroke="white"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M62 2H42.5C31.7304 2 23 10.7304 23 21.5V50"
              stroke="white"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M2 27H83V81C83 91.4934 74.4934 100 64 100H21C10.5066 100 2 91.4934 2 81V27Z"
              stroke="white"
              fill="none"
              stroke-width="4"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="near-buy-page__header">Confirm purchase</div>
        <div class="near-buy-page__meta">Metadata:</div>
        <div class="near-buy-page__meta-table">
          <table v-if="item">
            <tr>
              <td>ID</td>
              <td>
                {{
                  item.id.length > 30
                    ? item.id.substring(0, 30) + "..."
                    : item.id
                }}
              </td>
            </tr>
            <tr>
              <td>Name</td>
              <td>{{ item.meta.name }}</td>
            </tr>
            <tr v-if="item.bestSellOrder">
              <td>Price</td>
              <td>{{ item.bestSellOrder.makePrice }}</td>
            </tr>
            <tr>
              <td>Account balance</td>
              <td>{{ balance }}</td>
            </tr>
          </table>
        </div>

        <div class="near-buy-page__actions">
          <button
          v-if="item"
            class="btn btn--dark--white"
            :disabled="!item.bestSellOrder"
            @click="buy()"
          >
            Checkout
          </button>
        </div>
      </div>

      <div v-if="!connected">
        <div class="near-buy-page__logo">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="85"
            height="102"
            viewBox="0 0 85 102"
            fill="none"
          >
            <path
              d="M23 2H42.5C53.2696 2 62 10.7304 62 21.5V50"
              stroke="white"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M62 2H42.5C31.7304 2 23 10.7304 23 21.5V50"
              stroke="white"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M2 27H83V81C83 91.4934 74.4934 100 64 100H21C10.5066 100 2 91.4934 2 81V27Z"
              stroke="white"
              fill="none"
              stroke-width="4"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="near-buy-page__header">Select wallet</div>
        <div class="near-buy-page__name">Select one of available <a href="https://v-art.digital/blog/8656/" style="color: aliceblue;" target="_blank"> wallets: </a></div>
        <div class="near-buy-page__actions">
          <button
            class="btn btn--dark--white"
            @click="selectMetamask()"
            :disabled="connecting"
          >
            Metamask
          </button>
          <button class="btn btn--dark--white" disabled>Coming soon ...</button>
          <button class="btn btn--dark--white" disabled>Coming soon ...</button>
        </div>
      </div>
    </div>

    <message-dialog
      :visible="message.show"
      :type="message.type"
      :title="message.title"
      :message="message.message"
      @close="message.show = false"
      :closable="message.closable"
    />
  </div>
  <!-- <v-container fluid pa-8>
    <v-row class="header">
      <span>Select wallet</span>
    </v-row>
    <v-row class="text mt-7">
      <span>Connect</span>
      <span class="ml-2"><a href="" @click="showWallets()"> wallets</a></span>
    </v-row>
    <v-row class="mt-7">
      <v-btn
        class="btn"
        elevation="0"
        block
        @click="selectMetamask()"
        :disabled="connecting"
        > Metamask
        <span class="ws-btn-icon ml-2"><wallet-icon></wallet-icon></span>
      </v-btn>
    </v-row>
    <v-row class="mt-7">
      <v-btn class="btn-disabled" elevation="0" block
        > Coming soon
        <span class="ws-btn-icon ml-2"><wallet-icon></wallet-icon></span>
      </v-btn>
    </v-row>
    <v-row class="mt-7">
      <v-btn color="btn-disabled" elevation="0" block
        > Coming soon
        <span class="ws-btn-icon ml-2"><wallet-icon></wallet-icon></span>
      </v-btn>
    </v-row>
   
  </v-container> -->
</template>

<script>
import WalletIcon from "../icons/WalletIcon.vue";
import MessageDialog from "../components/MessageDialog.vue";
import RaribleService from "../services/rarible";
import axios from "axios";
import {
  Connector,
  InjectedWeb3ConnectionProvider,
  IConnectorStateProvider,
} from "@rarible/connector";
import { toItemId, toUnionAddress, toCollectionId } from "@rarible/types";
import { WalletConnectConnectionProvider } from "@rarible/connector-walletconnect";
import { mapEthereumWallet } from "@rarible/connector-helper";
import { createRaribleSdk } from "@rarible/sdk";
import { RequestCurrency } from "@rarible/sdk/build/common/domain";
import vArtDigitalApi from "../services/v-art-digital-api";
export default {
  components: {
    // WalletIcon,
    MessageDialog,
  },

  data() {
    return {
      connector: null,
      vArtApiToken: null,
      tokenId: null,
      newOwner: null,
      connecting: false,
      connected: null,
      balance: null,
      item: null,
      message: {
        closable: false,
        show: false,
        type: "error",
        title: "Check parameters",
        message: "Please check ",
      },
      environments: [
        { name: "production", chainId: 1, raribleEnv: "prod" },
        { name: "development", chainId: 4, raribleEnv: "staging" },
      ],
    };
  },
  created() {
    // Checking API Token
    this.vArtApiToken = this.$route.query.vArtApiToken;
    if (!this.vArtApiToken) {
      this.message.closable = false;
      this.message.title = "Parameters error";
      this.message.message = "Please check Api to ken in query parameters!";
      this.message.show = true;
      return
    } else {
      vArtDigitalApi.setApiToken(this.vArtApiToken);
    }

    // Checking NFT Token ID
    const tokenId = this.$route.query.tokenId;
    this.tokenId = tokenId;
    if (!this.vArtApiToken) {
      this.message.closable = false;
      this.message.title = "Parameters error";
      this.message.message = "Please check token ID in query parameters!";
      this.message.show = true;
      return
    } else {
      this.$store.commit("TOKEN_ID", tokenId);
    }

    // Checking newOwner
    const newOwner = this.$route.query.newOwner;
    this.newOwner = newOwner;
    if (!this.newOwner) {
      this.message.closable = false;
      this.message.title = "Parameters error";
      this.message.message = "Please check New Owner in query parameters!";
      this.message.show = true;
      return
    }
    // const sku = this.$route.query.sku;

    // console.log(tokenId);

    // const vArtApiToken =  this.$route.query.vArtApiToken;

    // // this.$store.commit("SKU", sku);
    this.checkParameters();
  },
  methods: {
    checkParameters() {
      const itemId = this.$store.getters["TOKEN_ID"];
      console.log(itemId);
      //   const sku = this.$store.getters["pay/SKU"];
      if (!itemId) {
        this.message.message = this.message.message + " TOKEN ID ";
        this.message.show = true;
      }
      //   if (!sku) {
      //     this.message.message = this.message.message + " SKU ";
      //   }
      //   if (!itemId || !sku) {

      //   }
    },
    showWallets() {
      window.open(
        "https://v-art.digital/what-crypto-wallets-are-available-on-the-v-art-marketplace/",
        "_blank"
      );
    },
    async loadData() {
      console.log('Loading data');
      this.connector.connection.subscribe(async (connection) => {
        if (connection.status == "connected") {
          const sdk = createRaribleSdk(
            connection.connection.wallet,
            this.environments.find((el) => el.name == process.env.VUE_APP_MODE)
              .raribleEnv
          );
          const balance = await sdk.balances.getBalance(
            "ETHEREUM:" + connection.connection.address,
            { "@type": "ETH" }
          );
          console.log('Currect account balance');
          console.log(balance);
          this.balance = balance;
        }
      });
      // const itemUrl = "https://api-staging.rarible.org/v0.1/items/";
      // const itemUrl = "https://api.rarible.org/v0.1/items/";
      const itemUrl = process.env.VUE_APP_RARIBLE_API_URL + "items/";
       console.log('getting from Rarible ');
      console.log(itemUrl + this.tokenId);
      const item = await axios.get(itemUrl + this.tokenId);
     
      this.item = item.data;
      console.log(this.item);
    },
    // async selectMetamask() {
    //   await this.connectMetamask().then((resp) => {
    //     console.log("next");
    //     const path = "eth-confirm?tokenId=" + this.tokenId;
    //     this.$router.push({ path: path });
    //   });
    // },
    buy() {
      this.connector.connection.subscribe(async (connection) => {
        if (connection.status == "connected") {
          const sdk = createRaribleSdk(
            connection.connection.wallet,
            this.environments.find((el) => el.name == process.env.VUE_APP_MODE)
              .raribleEnv
          );
          try {
            const { submit, maxAmount } = await sdk.order.buy({
              orderId: this.item.bestSellOrder.id,
            });
            const tx = await submit({
              amount: maxAmount,
            });
            tx.transaction.receipt
              .then((r) => {
                vArtDigitalApi
                  .patchProduct(this.tokenId, this.newOwner)
                  .then((resp) => {
                    this.message.closable = false;
                    this.message.title = "Item was successfully purchased";
                    this.message.message = "";
                    this.message.show = true;
                  })
                  .catch((err) => {
                    this.message.closable = false;
                    this.message.title = "Error while patching product";
                    this.message.message = "";
                    this.message.show = true;
                  });
              })
              .catch((err) => {
                this.message.closable = false;
                this.message.title = "Buying error!";
                this.message.message = "";
                this.message.show = true;
              });
          } catch (err) {
            // console.log('catch')
            // console.log(err)
            // console.log(err.message)
            // buyReject(err.message)
          }
        }
      });
    },
    async selectMetamask() {
      console.log("Connecting metamask");
      var connectedResolve, connectedReject;

      var connected = new Promise(function (resolve, reject) {
        connectedResolve = resolve;
        connectedReject = reject;
      });
      this.provider = new InjectedWeb3ConnectionProvider();
      this.wallet = mapEthereumWallet(this.provider);
      this.walletConnectProvider = new WalletConnectConnectionProvider({
        rpc: {
          1: "https://node-mainnet.rarible.com",
          4: "https://node-rinkeby.rarible.com",
        },
        chainId: this.environments.find(
          (el) => el.name == process.env.VUE_APP_MODE
        ).chainId,
      });
      this.walletConnect = mapEthereumWallet(this.walletConnectProvider);

      this.connector = Connector.create([this.wallet, this.walletConnect]);
      await this.connector.connection.subscribe((con) => {
        this.connection = con;
        console.log(this.connection.status);
        if (this.connection.status == "connected") {
          this.connected = true;
          this.loadData();
          connectedResolve();
        } else if (this.connection.status == "disconnected") {
          this.isMinting = false;
        } else if (this.connection.status == "connecting") {
          this.isMinting = true;
        }
      });
      const options = await this.connector.getOptions();
      await this.connector.connect(options[0]);
      await connected;
    },
  },
};
</script>

<style>
</style>