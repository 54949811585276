<template>
  <div class="near-mint-page">
    <div class="near-mint-page__container container">
      <div style="display: flex">
        <div class="near-mint-page__header">Mint NEAR token</div>
        <div class="near-mint-page__account">
          <div class="account-name" v-show="connectedAccount">
            Connected to: {{ connectedAccount }}
          </div>
          <div class="button">
            <button
              class="btn btn--light--pink"
              @click="signIn()"
              v-show="!connectedAccount"
            >
              Sign in
            </button>
            <button
              class="btn btn--light--pink"
              @click="signOut()"
              v-show="connectedAccount"
            >
              Sign out
            </button>
          </div>
        </div>
      </div>
      <div class="near-mint-page__form">
        <div class="row">
          <div class="column">
            <span>ID:</span>
            <input class="text-input" v-model="form.id" />
          </div>
          <div class="column">
            <span>Name:</span>
            <input class="text-input" v-model="form.name" />
          </div>
        </div>
        <div class="row">
          <div class="column">
            <span>Artist:</span>
            <input class="text-input" v-model="form.artist" />
          </div>
          <div class="column">
            <span>Genre:</span>
            <input class="text-input" v-model="form.genre" />
          </div>
        </div>
        <div class="row">
          <div class="column">
            <span>Bio:</span>
            <input class="text-input" v-model="form.bio" />
          </div>
        </div>
        <div class="row">
          <div class="column">
            <span>Year:</span>
            <input class="text-input" v-model="form.year" />
          </div>
          <div class="column">
            <span>Edition:</span>
            <input class="text-input" v-model="form.edition" />
          </div>

          <div class="column">
            <span>Medium:</span>
            <input class="text-input" v-model="form.medium" />
          </div>
        </div>
        <div class="row">
          <div class="column">
            <span>Price:</span>
            <input class="text-input" v-model="form.price" />
          </div>
          <div class="column">
            <span>Blockchain:</span>
            <input class="text-input" v-model="form.blockchain" />
          </div>
          <div class="column">
            <span>Quantity:</span>
            <input class="text-input" v-model="form.quantity" />
          </div>
        </div>
        <div class="row">
          <div class="column">
            <span>Description:</span>
            <input class="text-input" v-model="form.description" />
          </div>
        </div>

        <div class="near-mint-page__subheader">Artists wallets:</div>
        <div
          class="row"
          v-for="a in form.artistsWallets"
          :key="getArtistIndex(a)"
        >
          <div class="column">
            <span>ID:</span>
            <input class="text-input" v-model="a.id" />
          </div>
          <div class="column">
            <span>Value:</span>
            <input class="text-input" v-model="a.value" />
          </div>
          <div class="column">
            <button
              class="remove-button"
              @click="removeArtist(getArtistIndex(r))"
            >
              X
            </button>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <button
              class="btn btn--light--pink--transparent add-button"
              @click="addArtist()"
              :disabled="!isArtistsFilled"
            >
              + Add artist
            </button>
          </div>
        </div>
        <div class="near-mint-page__subheader">Royalties:</div>
        <div
          class="row"
          v-for="r in form.royaltiesWallets"
          :key="getRoyaltyIndex(r)"
        >
          <div class="column">
            <span>ID:</span>
            <input class="text-input" v-model="r.id" />
          </div>
          <div class="column">
            <span>Value:</span>
            <input class="text-input" v-model="r.value" />
          </div>
          <div class="column">
            <button
              class="remove-button"
              @click="removeRoyalty(getRoyaltyIndex(r))"
            >
              X
            </button>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <button
              class="btn btn--light--pink--transparent add-button"
              @click="addRoyalty()"
              :disabled="!isRoyaltiesFilled"
            >
              + Add royalty
            </button>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <span>Created by:</span>
            <input class="text-input" disabled v-model="form.createdBy" />
          </div>
          <!-- <div class="column">
            <span>Owned by:</span>
            <input class="text-input" disabled v-model="form.ownedBy" />
          </div> -->
        </div>
        <div class="row">
          <div class="column">
            <span>Preview HASH:</span>
            <input
              class="text-input"
              disabled
              v-model="form.media_preview_hash"
            />
          </div>
        </div>
        <div class="row">
          <div class="column">
            <span>Media HASH:</span>
            <input class="text-input" disabled v-model="form.media_hash" />
          </div>
        </div>
        <div class="row">
          <div class="column">
            <span>Certificate HASH:</span>
            <input
              class="text-input"
              disabled
              v-model="form.certificate_hash"
            />
          </div>
        </div>
        <div class="row">
          <div class="column">
            <span>Metadata HASH:</span>
            <input class="text-input" disabled v-model="form.metadata_hash" />
          </div>
        </div>
      </div>
      <div class="near-mint-page__buttons">
        <div class="near-mint-page__button">
          <button
            class="btn btn--light--pink"
            @click="mint()"
            :disabled="!connectedAccount || transactionHashes"
          >
            Mint
          </button>
        </div>
        <div class="near-mint-page__button">
          <button class="btn btn--light--pink" @click="sell()">Sell</button>
        </div>
      </div>
      <hr />
      <div class="near-mint-page__form">
        <div class="row">
          <div class="column">
            <span>Start price:</span>
            <input class="text-input" v-model="form.price" />
          </div>
          <div class="column">
            <span>Auction duration (sec):</span>
            <input class="text-input" v-model="this.auction.duration" />
          </div>
        </div>
        <div class="row">
          <div class="column">
            <span>Auction start time (epoch):</span>
            <input class="text-input" v-model="this.auction.startTime" />
          </div>
        </div>
        <div class="near-mint-page__button">
          <button class="btn btn--light--pink" @click="sell()">
            Sell on auction
          </button>
        </div>
      </div>
    </div>
  </div>
  <message-dialog
    :visible="message.show"
    :closable="message.closable"
    :type="message.type"
    :title="message.title"
    :message="message.message"
    @close="message.show = false"
  />
</template>

<script>
import vArtDigitalApi from "../services/v-art-digital-api";
import PinataService from "../services/pinata";
import CertificatesApi from "../services/certificates-api";
import FirebaseService from "../services/firebase-service";
import MessageDialog from "../components/MessageDialog.vue";
import moment from "moment";
import nearApi from "@/services/near-api";

export default {
  components: {
    MessageDialog,
  },
  data() {
    return {
      // product: null,
      // artist: null,
      // connectedAccount: null,
      // certificate: null,
      // transactionHashes: null,
      // isMinted: null,
      // isSold: null,
      vArtApiToken: null,
      productId: null,
      product: null,
      artist: null,
      connectedAccount: null,
      transactionHashes: null,

      minting: false,
      selling: false,

      form: {
        id: null,
        picture: null,
        name: null,
        medium: null,
        genre: null,
        bio: null,
        description: null,
        artist: null,
        createdBy: null,
        ownedBy: null,
        year: null,
        price: null,
        blockchain: null,
        filePath: null,
        artistsWallets: [],
        royaltiesWallets: [],
        quantity: null,
        edition: null,
        media_preview: null,
        media_preview_hash: null,
        media: null,
        media_hash: null,
        metadata: null,
        metadata_hash: null,
        certificate: null,
        certificate_hash: null,
        art_size: null
      },
      auction: {
        duration: 86400,
        startTime: 1657034158,
      },

      message: {
        show: false,
        closable: true,
        type: "success",
        title: "Title",
        message: "Message",
      },
    };
  },
  async created() {
    // Checking API Token
    this.vArtApiToken = this.$route.query.vArtApiToken;
    if (!this.vArtApiToken) {
      this.message.closable = false;
      this.message.title = "Parameters error";
      this.message.message = "Please check Api token in query parameters!";
      this.message.show = true;
    } else {
      vArtDigitalApi.setApiToken(this.vArtApiToken);
    }

    // Checking product ID
    this.productId = this.$route.query.productId;
    if (!this.productId) {
      this.message.closable = false;
      this.message.title = "Parameters error";
      this.message.message = "Please check Product ID in query parameters!";
      this.message.show = true;
    } else {
      // Getting product from API
      await vArtDigitalApi
        .getProductById(this.productId)
        .then((productResponce) => {
          this.product = productResponce.data;
          console.log(this.product);
        })
        .catch((err) => {
          this.message.closable = false;
          this.message.title = "Product error";
          this.message.message = "Error while getting product from v-art API!";
          this.message.show = true;
        });
      // Getting artist from API
      await vArtDigitalApi
        .getUserById(this.product.artist)
        .then((resp) => {
          this.artist = resp.data;
        })
        .catch((err) => {
          this.message.closable = false;
          this.message.title = "Artist error";
          this.message.message =
            "Error while getting user " +
            this.product.artist +
            " from v-art API!";
          this.message.show = true;
        });
      // Getting creator from API
      await vArtDigitalApi
        .getUserById(this.product.createdBy)
        .then((resp) => {
          this.form.createdBy = resp.data.name;
        })
        .catch((err) => {
          this.message.closable = false;
          this.message.title = "Artist error";
          this.message.message =
            "Error while getting user " +
            this.product.createdBy +
            " from v-art API!";
          this.message.show = true;
        });
      // // Getting owner from API
      // await vArtDigitalApi
      //   .getUserById(this.product.ownedBy)
      //   .then((resp) => {
      //     this.form.ownedBy = resp.data.name;
      //   })
      //   .catch((err) => {
      //     this.message.closable = false;
      //     this.message.title = "Artist error";
      //     this.message.message =
      //       "Error while getting user " +
      //       this.product.ownedBy +
      //       " from v-art API!";
      //     this.message.show = true;
      //   });
      this.unpackProduct();

      // Checking Transaction Hash
      this.transactionHashes = this.$route.query.transactionHashes;
      // Checking Transaction Hash ONLY FOR HASH routing
      const href = window.location.href;
      if (href.includes("transactionHashes")) {
        this.transactionHashes = true;
      }
      const action = localStorage.action;
      const tokenId = localStorage.tokenId;
      const actionDate = localStorage.actionDate;

      if (
        this.transactionHashes &&
        action == "sell" &&
        tokenId == this.form.id
      ) {
        await vArtDigitalApi.approveProduct(this.form.id).catch((err) => {
          this.message.closable = false;
          this.message.title = "Approving error";
          this.message.message = "Error while approve product " + this.form.id;
          this.message.show = true;
        });
      }
    }
    // Initializing Services
    try {
      FirebaseService.init();
      await nearApi.init();
    } catch (err) {
      this.message.closable = false;
      this.message.title = "Error while initializing";
      this.message.message = err.message;
      this.message.show = true;
    }

    // Getting Near account
    this.connectedAccount = await nearApi.getAccount();
  },
  computed: {
    isRoyaltiesFilled() {
      var result = true;
      if (this.form.royaltiesWallets.length == 0) {
        return result;
      }
      this.form.royaltiesWallets.forEach((attr) => {
        if (
          !attr.id ||
          attr.id.length < 1 ||
          !attr.value ||
          attr.value.length < 1
        ) {
          result = false;
        }
      });
      return result;
    },
    isArtistsFilled() {
      var result = true;
      if (this.form.artistsWallets.length == 0) {
        return result;
      }
      this.form.artistsWallets.forEach((attr) => {
        if (
          !attr.id ||
          attr.id.length < 1 ||
          !attr.value ||
          attr.value.length < 1
        ) {
          result = false;
        }
      });
      return result;
    },
  },
  methods: {
    async mint() {
      await this.pinImagePreview();
      await this.pinOriginalFile();
      await this.createCertificate();
      await this.pinCertificate();
      await this.pinJsonMetadata();
      localStorage.action = "mint";
      localStorage.tokenId = this.form.id;
      localStorage.actionDate = new Date();
      await this.mintToken();
    },
    async sell() {
      localStorage.action = "sell";
      localStorage.tokenId = this.form.id;
      localStorage.actionDate = new Date();
      this.sellToken();
    },
    async unpackProduct() {
      this.form.id = this.product._id;
      this.form.name = this.product.name;
      this.form.filePath = this.product.filePath;
      this.form.picture = this.product.picture;
      this.form.artist = this.artist.name;
      this.form.year = this.product.year;
      this.form.medium = this.product.medium;
      this.form.genre = this.product.genre;
      this.form.bio = this.product.bio;
      this.form.description = this.product.description;
      this.form.price = this.product.price.toString();
      this.form.blockchain = this.product.blockchain;
      this.form.quantity = this.product.quantity;
      this.form.edition = this.product.edition;
      this.art_size = this.product.size;
      this.unpackRoyalties();
      this.unpackArtists();
    },
    unpackArtists() {
      this.form.artistsWallets = [];
      for (var r in this.product.artistsWallets) {
        this.form.artistsWallets.push({
          id: r,
          value: this.product.artistsWallets[r].toString(),
        });
      }
    },
    packArtists() {
      const artists = {};
      this.form.artistsWallets.forEach((a) => {
        artists[a.id] = parseInt(a.value);
      });
      return artists;
    },
    getArtistIndex(attr) {
      return this.form.artistsWallets.findIndex(function (item, i) {
        return item == attr;
      });
    },
    removeArtist(index) {
      this.form.artistsWallets.splice(index, 1);
    },
    addArtist() {
      this.form.artistsWallets.push({ id: "", value: "" });
    },
    unpackRoyalties() {
      this.form.royaltiesWallets = [];
      for (var r in this.product.royaltiesWallets) {
        this.form.royaltiesWallets.push({
          id: r,
          value: this.product.royaltiesWallets[r].toString(),
        });
      }
    },
    packRoyalties() {
      const royalties = {};
      this.form.royaltiesWallets.forEach((r) => {
        royalties[r.id] = parseInt(r.value);
      });
      return royalties;
    },
    getRoyaltyIndex(attr) {
      return this.form.royaltiesWallets.findIndex(function (item, i) {
        return item == attr;
      });
    },
    removeRoyalty(index) {
      this.form.royaltiesWallets.splice(index, 1);
    },
    addRoyalty() {
      this.form.royaltiesWallets.push({ id: "", value: "" });
    },
    async pinImagePreview() {
      const blob = await FirebaseService.getBlob(
        "artwork-preview",
        this.form.picture
      );
      await PinataService.pinFile(blob, "PREVIEW " + this.form.name).then(
        (resp) => {
          this.form.media_preview =
            process.env.VUE_APP_IPFS_GATEWAY + resp.ipfsHash;
          this.form.media_preview_hash = resp.hexB64;
        }
      );
    },
    async pinOriginalFile() {
      const blob = await FirebaseService.getBlob("artwork", this.form.filePath);
      await PinataService.pinFile(blob, "REFERENCE " + this.form.name).then(
        (resp) => {
          this.form.media = process.env.VUE_APP_IPFS_GATEWAY + resp.ipfsHash;
          this.form.media_hash = resp.hexB64;
        }
      );
    },
    async buildCertificateData() {
      console.log(this.form.picture);
      let blob = await FirebaseService.getBlob(
        "artwork-preview",
        this.form.picture
      );
      // if (this.form.blockchain.toLowerCase() != "ethereum") {
      // alert("Валюта принудительно изменена на ETHEREUM!");
      // this.form.blockchain = "ethereum";
      // }

      const data = {
        token_id: this.form.id,
        preview_image: blob,
        author: this.form.artist,
        name: this.form.name,
        year: this.form.year,
        edition: this.form.edition,
        quantity: this.form.quantity,
        url: this.form.media,
        currency: this.form.blockchain.toLowerCase(),
        contract: nearApi.getNftContractId(),
        genre: this.form.genre,
        dimensions: this.product.size,
        slug_link: this.form.id,
        copyrights: [
          "adaption",
          "storage",
          "placement",
          "publication",
          "metadata",
          "demonstration",
          "personal_use",
          "advertising",
        ],
        creation_date: moment(new Date()).format("DD/MM/YYYY HH:mm:ss"),
      };
      return data;
    },
    async createCertificate() {
      const metadata = await this.buildCertificateData();
      // console.log(metadata);
      const cert = await CertificatesApi.createCertificate(metadata);
      this.certificate = cert;
      // console.log(this.certificate);
      // console.log(cert);
    },

    async downloadCertificate() {
      // console.log(this.certificate.certificate_download_url);
      const certificateBlob = await CertificatesApi.downloadCertificate(
        this.certificate.certificate_download_url.split("/").at(-1)
      );
      return certificateBlob;
      // const certId = this.certificate.certificate_download_url
      //   .split("/")
      //   .at(-1);
      // const pdfUrl =
      //   process.env.VUE_APP_CERTIFICATES_URL +
      //   // process.env.VUE_APP_CERTIFICATES_PROXY_PREFIX +
      //   "/storage/certificates/" +
      //   certId +
      //   "/certificate.pdf";
      // return await axios({
      //   url: pdfUrl,
      //   method: "GET",
      //   responseType: "blob",
      // });
    },
    async pinCertificate() {
      const blob = await this.downloadCertificate();
      const fixedBlob = await this.fixPdf(blob.data);
      await PinataService.pinFile(fixedBlob, "CERT " + this.form.name).then(
        (resp) => {
          this.form.certificate =
            process.env.VUE_APP_IPFS_GATEWAY + resp.ipfsHash;
          this.form.certificate_hash = resp.hexB64;
        }
      );
    },
    async pinJsonMetadata() {
      const metadata = {
        name: this.form.id + ".json",
        product: this.form,
        certificate: this.certificate,
      };
      const resp = await PinataService.pinJson(
        metadata,
        "METADATA " + this.form.name
      );
      this.form.metadata = process.env.VUE_APP_IPFS_GATEWAY + resp.ipfsHash;
      this.form.metadata_hash = resp.hexB64;
    },
    createMintData() {
      const metadata = {
        token_id: this.form.id,
        token_metadata: {
          title: this.form.name,
          description: this.form.description,
          media: this.form.media_preview,
          media_hash: this.form.media_preview_hash,
          copies: this.form.quantity, // !!!!!!!!!!!!!!!!!!!
          issued_at: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          expires_at: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          starts_at: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          updated_at: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          reference: this.form.metadata,
          reference_hash: this.form.metadata_hash,
        },
        token_royalties: this.packRoyalties(),
        price: this.form.price,
      };
      return metadata;
    },
    async mintToken() {
      if (!nearApi.isSignedIn()) {
        this.signIn();
      } else {
        await nearApi.mintToken(this.createMintData());
      }
    },
    createSellData() {
      const msg = {
        sale_conditions: {
          price: Number(
            parseFloat(this.form.price) * 1000000000000000000000000
          ).toLocaleString("fullwide", { useGrouping: false }),
          initial_payments: this.packArtists(),
        },
      };
      const data = {
        token_id: this.form.id,
        account_id: "",
        msg: JSON.stringify(msg),
      };
      return data;
    },
    createSellAuctionData() {
      const msg = {
        sale_conditions: {
          price: Number(
            parseFloat(this.form.price) * 1000000000000000000000000
          ).toLocaleString("fullwide", { useGrouping: false }),
          initial_payments: this.packArtists(),
          auction_details: {
            duration: this.auction.duration,
            start_time: this.auction.startTime
          }
        },
      };
      const data = {
        token_id: this.form.id,
        account_id: "",
        msg: JSON.stringify(msg),
      };
      return data;
    },
    sellToken() {
      // this.createSellData();
      nearApi.sellToken(this.createSellData());
    },
    sellTokenAuction() {
      nearApi.sellTokenAuction(this.createSellAuctionData());
    },
    pathProduct() {},
    signIn() {
      nearApi.signInMarket();
    },
    signOut() {
      this.connectedAccount = null;
      nearApi.signOut();
    },
    async fixPdf(blob) {
      let p = new Promise((resolve, reject) => {
        var fileReader = new FileReader();
        fileReader.onload = function (event) {
          resolve(event.target.result);
        };
        fileReader.onerror = reject;
        fileReader.readAsArrayBuffer(blob);
      });
      const arr = await p;
      const uint8array = new Uint8Array(arr);
      const pdfTriggerIndex = this.findPdfTrigger(uint8array);
      const arr2 = arr.slice(pdfTriggerIndex);
      return new Blob([arr2], { type: "application/pdf" });
    },
    findPdfTrigger(array) {
      var result = -1;
      const pdfTrigger = new Uint8Array([37, 80, 68, 70, 45, 49, 46, 55]);
      const arrEquals = (a, b) =>
        a.length === b.length && a.every((v, i) => v === b[i]);
      array.forEach((e, index) => {
        if (e == pdfTrigger[0] && result == -1) {
          const tmpArray = array.slice(index, index + pdfTrigger.length);
          if (arrEquals(pdfTrigger, tmpArray)) {
            result = index;
          }
        }
      });
      return result;
    },
  },
};
</script>

<style lang="scss">
.class {
  background-color: red;
}
</style>