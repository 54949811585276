<template>
  <div class="near-buy-page">
    <div class="near-buy-page__container container">
      <div class="near-buy-page__logo">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="85"
          height="102"
          viewBox="0 0 85 102"
          fill="none"
        >
          <path
            d="M23 2H42.5C53.2696 2 62 10.7304 62 21.5V50"
            stroke="white"
            fill="none"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M62 2H42.5C31.7304 2 23 10.7304 23 21.5V50"
            stroke="white"
            fill="none"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2 27H83V81C83 91.4934 74.4934 100 64 100H21C10.5066 100 2 91.4934 2 81V27Z"
            stroke="white"
            fill="none"
            stroke-width="4"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="near-buy-page__header">Select wallet</div>
      <div class="near-buy-page__name">Select one of available wallets:</div>
      <div class="near-buy-page__actions">
        <button class="btn btn--dark--white">Metamask</button>
        <button class="btn btn--dark--white" disabled>Coming soon</button>
        <button class="btn btn--dark--white" disabled>Coming soon</button>
      </div>
    </div>
  </div>
      <message-dialog
      :visible="message.show"
      :closable="message.closable"
      :type="message.type"
      :title="message.title"
      :message="message.message"
      @close="message.show = false"
    />
</template>

<script>
import vArtDigitalApi from "@/services/v-art-digital-api";
import MessageDialog from "../components/MessageDialog.vue";
export default {
        components: {
      MessageDialog,
    },
  data() {
    return {
      tokenId: null,
      price: null,
      connectedAccount: null,
      vArtApiToken: null,
      buyerId: null,
      message: {
        show: false,
        closable: false,
        type: "success",
        title: "Title",
        message: "Message",
      },
     
    };
  },
  async created() {
    console.log('Created');
    // Checking API Token
    this.vArtApiToken = this.$route.query.vArtApiToken;
    if (!this.vArtApiToken) {
        console.log('No api token');
      this.message.closable = false;
      this.message.title = "Parameters error";
      this.message.message = "Please check Api token in query parameters!";
      this.message.show = true;
    } else {
      vArtDigitalApi.setApiToken(this.vArtApiToken);
    }

    this.tokenId = this.$route.query.tokenId;

    if (!this.tokenId) {
      this.message.type = "error";
      this.message.title = "Token ID error";
      this.message.closable = false;
      this.message.message = "Please check token ID in url!";
      this.message.show = true;
      return;
    }

    this.buyerId = this.$route.query.buyerId;
    if (!this.buyerId) {
      this.message.type = "error";
      this.message.title = "Buyer ID error";
      this.message.closable = false;
      this.message.message = "Please check buyer ID in url!";
      this.message.show = true;
      return;
    }
  },
};
</script>

<style>
</style>