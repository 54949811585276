<template>
  <div class="near-mint-page">
    <div class="near-mint-page__container container">
      <div style="display: flex">
        <div class="near-mint-page__header">Mint ETH token</div>
        <!-- <div class="near-mint-page__account">
          <div class="account-name" v-show="connectedAccount">
            Connected to: {{ connectedAccount }}
          </div>
          <div class="button">
            <button
              class="btn btn--light--pink"
              @click="signIn()"
              v-show="!connectedAccount"
            >
              Sign in
            </button>
            <button
              class="btn btn--light--pink"
              @click="signOut()"
              v-show="connectedAccount"
            >
              Sign out
            </button>
          </div>
        </div> -->
      </div>
      <div class="near-mint-page__form">
        <div class="row">
          <div class="column">
            <span>TOKEN ID:</span>
            <input class="text-input" v-model="tokenId" disabled />
          </div>
        </div>
        <div class="row">
          <div class="column">
            <span>ID:</span>
            <input class="text-input" v-model="form.id" />
          </div>
          <div class="column">
            <span>Name:</span>
            <input class="text-input" v-model="form.name" />
          </div>
        </div>
        <div class="row">
          <div class="column">
            <span>Collection ID:</span>
            <input class="text-input" v-model="form.collectionId" />
          </div>
        </div>
        <div class="row">
          <div class="column">
            <span>Artist:</span>
            <input class="text-input" v-model="form.artist" />
          </div>
          <div class="column">
            <span>Genre:</span>
            <input class="text-input" v-model="form.genre" />
          </div>
        </div>
        <div class="row">
          <div class="column">
            <span>Bio:</span>
            <input class="text-input" v-model="form.bio" />
          </div>
        </div>
        <div class="row">
          <div class="column">
            <span>Year:</span>
            <input class="text-input" v-model="form.year" />
          </div>
          <div class="column">
            <span>Edition:</span>
            <input class="text-input" v-model="form.edition" />
          </div>

          <div class="column">
            <span>Medium:</span>
            <input class="text-input" v-model="form.medium" />
          </div>
        </div>
        <div class="row">
          <div class="column">
            <span>Price:</span>
            <input class="text-input" v-model="form.price" />
          </div>
          <div class="column">
            <span>Blockchain:</span>
            <input class="text-input" v-model="form.blockchain" />
          </div>
          <div class="column">
            <span>Quantity:</span>
            <input class="text-input" v-model="form.quantity" />
          </div>
        </div>
        <div class="row">
          <div class="column">
            <span>Description:</span>
            <input class="text-input" v-model="form.description" />
          </div>
        </div>
        <div class="row">
          <div class="column">
            <span>Certificate linssk:</span>
            <input class="text-input" v-model="form.cretificateLink" />
          </div>
        </div>
        <div class="row">
          <div class="column">
            <span>Size:</span>
            <input class="text-input" v-model="form.size_art"/>
          </div>
        </div>
        <div class="near-mint-page__subheader">Artists wallets:</div>
        <div
          class="row"
          v-for="a in form.artistsWallets"
          :key="getArtistIndex(a)"
        >
          <div class="column">
            <span>ID:</span>
            <input class="text-input" v-model="a.id" />
          </div>
          <div class="column">
            <span>Value:</span>
            <input class="text-input" v-model="a.value" />
          </div>
          <div class="column">
            <button
              class="remove-button"
              @click="removeArtist(getArtistIndex(r))"
            >
              X
            </button>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <button
              class="btn btn--light--pink--transparent add-button"
              @click="addArtist()"
              :disabled="!isArtistsFilled"
            >
              + Add artist
            </button>
          </div>
        </div>
        <div class="near-mint-page__subheader">Royalties:</div>
        <div
          class="row"
          v-for="r in form.royaltiesWallets"
          :key="getRoyaltyIndex(r)"
        >
          <div class="column">
            <span>ID:</span>
            <input class="text-input" v-model="r.id" />
          </div>
          <div class="column">
            <span>Value:</span>
            <input class="text-input" v-model="r.value" />
          </div>
          <div class="column">
            <button
              class="remove-button"
              @click="removeRoyalty(getRoyaltyIndex(r))"
            >
              X
            </button>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <button
              class="btn btn--light--pink--transparent add-button"
              @click="addRoyalty()"
              :disabled="!isRoyaltiesFilled"
            >
              + Add royalty
            </button>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <span>Created by:</span>
            <input class="text-input" disabled v-model="form.createdBy" />
          </div>
          <div class="column">
            <span>Owned by:</span>
            <input class="text-input" disabled v-model="form.ownedBy" />
          </div>
        </div>
        <div class="row">
          <div class="column">
            <span>Preview HASH:</span>
            <input
              class="text-input"
              disabled
              v-model="form.media_preview_hash"
            />
          </div>
        </div>
        <div class="row">
          <div class="column">
            <span>Media HASH:</span>
            <input class="text-input" disabled v-model="form.media_hash" />
          </div>
        </div>
        <div class="row">
          <div class="column">
            <span>Certificate HASH:</span>
            <input
              class="text-input"
              disabled
              v-model="form.certificate_hash"
            />
          </div>
        </div>
        <div class="row">
          <div class="column">
            <span>Metadata HASH:</span>
            <input class="text-input" disabled v-model="form.metadata_hash" />
          </div>
        </div>
      </div>
      <div class="near-mint-page__buttons">
        <div class="near-mint-page__button">
          <button class="btn btn--light--pink" @click="mint()">Mint</button>
        </div>
        <div class="near-mint-page__button">
          <button class="btn btn--light--pink" @click="create_certificate()">Create Certificate</button>
        </div>
        <div class="near-mint-page__button">
          <button class="btn btn--light--pink" @click="sellToken()">
            Sell
          </button>
        </div>
         <div class="near-mint-page__button">
          <button class="btn btn--light--pink" @click="sellToken()">
            Sell on auction
          </button>
        </div>
      </div>
    </div>
  </div>
  <message-dialog
    :visible="message.show"
    :closable="message.closable"
    :type="message.type"
    :title="message.title"
    :message="message.message"
    @close="message.show = false"
  />
</template>

<script>
import vArtDigitalApi from "../services/v-art-digital-api";
import PinataService from "../services/pinata";
import CertificatesApi from "../services/certificates-api";
import FirebaseService from "../services/firebase-service";
import MessageDialog from "../components/MessageDialog.vue";
import moment from "moment";
import nearApi from "@/services/near-api";
import axios from "axios";
import {
  Connector,
  InjectedWeb3ConnectionProvider,
  IConnectorStateProvider,
} from "@rarible/connector";
import { toItemId, toUnionAddress, toCollectionId } from "@rarible/types";
import { WalletConnectConnectionProvider } from "@rarible/connector-walletconnect";
import { mapEthereumWallet } from "@rarible/connector-helper";
import { createRaribleSdk } from "@rarible/sdk";
import  { RequestCurrency } from "@rarible/sdk/build/common/domain"
export default {
  components: {
    MessageDialog,
  },
  data() {
    return {
      product: null,
      artist: null,
      connectedAccount: null,
      certificate: null,
      transactionHashes: null,
      isMinted: null,
      isSold: null,
      tokenId: "",
      form: {
        id: null,
        picture: null,
        name: null,
        medium: null,
        genre: null,
        bio: null,
        description: null,
        artist: null,
        createdBy: null,
        ownedBy: null,
        year: null,
        price: null,
        blockchain: null,
        filePath: null,
        artistsWallets: [],
        royaltiesWallets: [],
        quantity: null,
        edition: null,
        media_preview: null,
        media_preview_hash: null,
        media: null,
        media_hash: null,
        metadata: null,
        metadata_hash: null,
        certificate: null,
        certificate_hash: null,
        collectionId: "0x42c08684f48e49af15dc4b46b8fad1a0c01e6ebf",
        cretificateLink: "",
        size_art: "",
      },

      message: {
        show: false,
        closable: true,
        type: "success",
        title: "Title",
        message: "Message",
      },

      environments: [
        { name: "production", chainId: 1, raribleEnv: "prod" },
        { name: "development", chainId: 4, raribleEnv: "staging" },
      ],
    };
  },
  async created() {
    // Checking API Token
    this.vArtApiToken = this.$route.query.vArtApiToken;
    if (!this.vArtApiToken) {
      this.message.closable = false;
      this.message.title = "Parameters error";
      this.message.message = "Please check Api token in query parameters!";
      this.message.show = true;
    } else {
      vArtDigitalApi.setApiToken(this.vArtApiToken);
    }

    // Checking product ID
    this.productId = this.$route.query.productId;
    if (!this.productId) {
      this.message.closable = false;
      this.message.title = "Parameters error";
      this.message.message = "Please check Product ID in query parameters!";
      this.message.show = true;
    } else {
      // Getting product from API
      await vArtDigitalApi
        .getProductById(this.productId)
        .then((productResponce) => {
          this.product = productResponce.data;
        })
        .catch((err) => {
          this.message.closable = false;
          this.message.title = "Product error";
          this.message.message = "Error while getting product from v-art API!";
          this.message.show = true;
        });
      // Getting artist from API
      await vArtDigitalApi
        .getUserById(this.product.artist)
        .then((resp) => {
          this.artist = resp.data;
        })
        .catch((err) => {
          this.message.closable = false;
          this.message.title = "Artist error";
          this.message.message =
            "Error while getting user " +
            this.product.artist +
            " from v-art API!";
          this.message.show = true;
        });
      // Getting creator from API
      await vArtDigitalApi
        .getUserById(this.product.createdBy)
        .then((resp) => {
          this.form.createdBy = resp.data.name;
        })
        .catch((err) => {
          this.message.closable = false;
          this.message.title = "Artist error";
          this.message.message =
            "Error while getting user " +
            this.product.createdBy +
            " from v-art API!";
          this.message.show = true;
        });
      // Getting owner from API
      await vArtDigitalApi
        .getUserById(this.product.ownedBy)
        .then((resp) => {
          this.form.ownedBy = resp.data.name;
        })
        .catch((err) => {
          this.message.closable = false;
          this.message.title = "Artist error";
          this.message.message =
            "Error while getting user " +
            this.product.ownedBy +
            " from v-art API!";
          this.message.show = true;
        });
      this.unpackProduct();
    }

    // Checking Transaction Hash
    // this.transactionHashes = this.$route.query.transactionHashes;

    // Checking Transaction Hash ONLY FOR HASH routing
    //   const href = window.location.href;
    //   if (href.includes("transactionHashes")) {
    //     this.transactionHashes = true;
    //   }

    //   const action = localStorage.action;
    //   const tokenId = localStorage.tokenId;
    //   const actionDate = localStorage.actionDate;

    //   if (
    //     this.transactionHashes &&
    //     action == "sell" &&
    //     tokenId == this.form.id
    //   ) {
    //     await vArtDigitalApi.approveProduct(this.form.id).catch((err) => {
    //       this.message.closable = false;
    //       this.message.title = "Approving error";
    //       this.message.message = "Error while approve product " + this.form.id;
    //       this.message.show = true;
    //     });
    //   }
    // }
    //Initializing Services
    try {
      FirebaseService.init();
      // await nearApi.init();
    } catch (err) {
      this.message.closable = false;
      this.message.title = "Error while initializing";
      this.message.message = err.message;
      this.message.show = true;
    }

    // // Getting Near account
    // this.connectedAccount = await nearApi.getAccount();
  },
  computed: {
    isRoyaltiesFilled() {
      var result = true;
      if (this.form.royaltiesWallets.length == 0) {
        return result;
      }
      this.form.royaltiesWallets.forEach((attr) => {
        if (
          !attr.id ||
          attr.id.length < 1 ||
          !attr.value ||
          attr.value.length < 1
        ) {
          result = false;
        }
      });
      return result;
    },
    isArtistsFilled() {
      var result = true;
      if (this.form.artistsWallets.length == 0) {
        return result;
      }
      this.form.artistsWallets.forEach((attr) => {
        if (
          !attr.id ||
          attr.id.length < 1 ||
          !attr.value ||
          attr.value.length < 1
        ) {
          result = false;
        }
      });
      return result;
    },
  },
  methods: {
    async connectMetamask() {
      console.log("Connecting metamask");
      var connectedResolve, connectedReject;

      var connected = new Promise(function (resolve, reject) {
        connectedResolve = resolve;
        connectedReject = reject;
      });
      this.provider = new InjectedWeb3ConnectionProvider();
      this.wallet = mapEthereumWallet(this.provider);
      this.walletConnectProvider = new WalletConnectConnectionProvider({
        rpc: {
          1: "https://node-mainnet.rarible.com",
          4: "https://node-rinkeby.rarible.com",
        },
        chainId: this.environments.find(
          (el) => el.name == process.env.VUE_APP_MODE
        ).chainId,
      });
      this.walletConnect = mapEthereumWallet(this.walletConnectProvider);

      this.connector = Connector.create([this.wallet, this.walletConnect]);
      await this.connector.connection.subscribe((con) => {
        this.connection = con;
        console.log(this.connection.status);
        if (this.connection.status == "connected") {
          connectedResolve();
        } else if (this.connection.status == "disconnected") {
          this.isMinting = false;
        } else if (this.connection.status == "connecting") {
          this.isMinting = true;
        }
      });
      const options = await this.connector.getOptions();
      await this.connector.connect(options[0]);
      await connected;
    },
    sendMessage(type, title, message) {
      (this.message.type = type),
        (this.message.title = title),
        (this.message.message = message);
      this.message.show = true;
    },
    generateRoyalties() {
      let royalties = [];
      this.form.royaltiesWallets.forEach((r) => {
        royalties.push({
          account: "ETHEREUM:" + r.id,
          value: parseInt(r.value),
        });
      });
      return royalties;
    },
    generateCreators() {
      let creators = [];
      this.form.artistsWallets.forEach((r) => {
        creators.push({
          account: "ETHEREUM:" + r.id,
          value: parseInt(r.value),
        });
      });
      return creators;
    },
    async mint() {
      this.connectMetamask();
      await this.pinImagePreview();
      await this.pinOriginalFile();
      await this.pinJsonMetadata();
      await this.connector.connection.subscribe(async ({ status, con }) => {
        if (status === "connected") {
          const sdk = createRaribleSdk(
            this.connection.connection.wallet,
            // "staging"
            this.environments.find((el) => el.name == process.env.VUE_APP_MODE)
              .raribleEnv
          );
          const collectionId = "ETHEREUM:" + this.form.collectionId;
          console.log(collectionId);
          const collection = await sdk.apis.collection.getCollectionById({
            collection: collectionId, 
          });

          const mint = await sdk.nft.mint({ collection });
          const result = await mint
            .submit({
              uri: "ipfs:/" + this.form.metadata_hash,
              // royalties: this.generateRoyalties(),
              // creators: this.generateCreators(),
              supply: 1,
              lazyMint: true,
            })
            .then((resp) => {
              this.tokenId = resp.itemId;
              this.isMinting = false;
              const tid = this.tokenId.length > 30 ? this.tokenId.substring(0, 30)+ '...' : this.tokenId
              const msg =  "You minted token with ID: " + tid
              console.log(msg);
              this.sendMessage(
                "success",
                "Minting success",
                msg
              );
              this.create_certificate();
            })
            .catch((err) => {
              console.log(err);
              this.isMinting = false;
            });
        }
      });
      

      // after mint --- called before mint!!!
      // await this.createCertificate();
      // await this.pinCertificate();

      // localStorage.action = "mint";
      // localStorage.tokenId = this.form.id;
      // localStorage.actionDate = new Date();

      // console.log("media: " + this.form.media);
      // console.log("media_preview: " + this.form.media_preview);
      // console.log("metadata: " + this.form.metadata);
    },
    async create_certificate() {
      await this.createCertificate();
      await this.pinCertificate();

      localStorage.action = "mint";
      localStorage.tokenId = this.form.id;
      localStorage.actionDate = new Date();

      console.log("media: " + this.form.media);
      console.log("media_preview: " + this.form.media_preview);
      console.log("metadata: " + this.form.metadata);
    },
    async unpackProduct() {
      this.form.id = this.product._id;
      this.form.name = this.product.name;
      this.form.filePath = this.product.filePath;
      this.form.picture = this.product.picture;
      this.form.artist = this.artist.name;
      this.form.year = this.product.year;
      this.form.medium = this.product.medium;
      this.form.genre = this.product.genre;
      this.form.bio = this.product.bio;
      this.form.description = this.product.description;
      this.form.price = this.product.price.toString();
      this.form.blockchain = this.product.blockchain;
      this.form.quantity = this.product.quantity;
      this.form.edition = this.product.edition;
      // this.form.collectionId = this.product.collectionId;
      this.form.size_art = this.product?.size;
      this.unpackRoyalties();
      this.unpackArtists();
    },
    unpackArtists() {
      this.form.artistsWallets = [];
      for (var r in this.product.artistsWallets) {
        this.form.artistsWallets.push({
          id: r,
          value: this.product.artistsWallets[r].toString(),
        });
      }
    },
    packArtists() {
      const artists = {};
      this.form.artistsWallets.forEach((a) => {
        artists[a.id] = parseInt(a.value);
      });
      return artists;
    },
    getArtistIndex(attr) {
      return this.form.artistsWallets.findIndex(function (item, i) {
        return item == attr;
      });
    },
    removeArtist(index) {
      this.form.artistsWallets.splice(index, 1);
    },
    addArtist() {
      this.form.artistsWallets.push({ id: "", value: "" });
    },
    unpackRoyalties() {
      this.form.royaltiesWallets = [];
      for (var r in this.product.royaltiesWallets) {
        this.form.royaltiesWallets.push({
          id: r,
          value: this.product.royaltiesWallets[r].toString(),
        });
      }
    },
    packRoyalties() {
      const royalties = {};
      this.form.royaltiesWallets.forEach((r) => {
        royalties[r.id] = parseInt(r.value);
      });
      return royalties;
    },
    getRoyaltyIndex(attr) {
      return this.form.royaltiesWallets.findIndex(function (item, i) {
        return item == attr;
      });
    },
    removeRoyalty(index) {
      this.form.royaltiesWallets.splice(index, 1);
    },
    addRoyalty() {
      this.form.royaltiesWallets.push({ id: "", value: "" });
    },
    async pinImagePreview() {
      const blob = await FirebaseService.getBlob(
        "artwork-preview",
        this.form.picture
      );
      await PinataService.pinFile(blob, "PREVIEW " + this.form.name).then(
        (resp) => {
          this.form.media_preview =
            process.env.VUE_APP_IPFS_GATEWAY + resp.ipfsHash;
          this.form.media_preview_hash = resp.ipfsHash;
        }
      );
    },
    async pinOriginalFile() {
      const blob = await FirebaseService.getBlob("artwork", this.form.filePath);
      await PinataService.pinFile(blob, "REFERENCE " + this.form.name).then(
        (resp) => {
          this.form.media = process.env.VUE_APP_IPFS_GATEWAY + resp.ipfsHash;
          this.form.media_hash = resp.ipfsHash;
        }
      );
    },
    async buildCertificateData() {
      // while(this.tokenId=="")
      // {
      //   console.log("no_token");
      // }
      console.log(this.form.picture);
      let blob = await FirebaseService.getBlob(
        "artwork-preview",
        this.form.picture
      );
      console.log(blob);
      // blob = blob.slice(0, blob.size, "image/jpeg")

      if (this.form.blockchain.toLowerCase() != "ethereum") {
        alert("Валюта принудительно изменена на ETHEREUM!");
        this.form.blockchain = "ethereum";
      }

      const result_token = this.tokenId.split('ETHEREUM:' + this.form.collectionId+':').join('');
      const data = {
        token_id: result_token,
        preview_image: blob,
        author: this.form.artist,
        name: this.form.name,
        year: this.form.year,
        edition: this.form.edition,
        size_art: this.form.size_art,
        quantity: this.form.quantity,
        url: this.form.media,
        currency: this.form.blockchain.toLowerCase(),
        contract: this.form.collectionId,
        genre: this.form.genre,
        dimensions: this.form.size_art,
        slug_link: this.form.id,
        copyrights: [
          "adaption",
          "storage",
          "placement",
          "publication",
          "metadata",
          "demonstration",
          "personal_use",
          "advertising",
        ],
        creation_date: moment(new Date()).format("DD/MM/YYYY HH:mm:ss"),
      };
      return data;
    },
    async createCertificate() {
      const metadata = await this.buildCertificateData();
      console.log(metadata);
      const cert = await CertificatesApi.createCertificate(metadata);
      this.certificate = cert;
    },
    async downloadCertificate() {
      const certificateBlob = await CertificatesApi.downloadCertificate(
        this.certificate.certificate_download_url.split("/").at(-1)
      );
      return certificateBlob;
      // const certId = this.certificate.certificate_download_url
      //   .split("/")
      //   .at(-1);
      // const pdfUrl =
      //   process.env.VUE_APP_CERTIFICATES_URL +
      //   // process.env.VUE_APP_CERTIFICATES_PROXY_PREFIX +
      //   "/storage/certificates/" +
      //   certId +
      //   "/certificate.pdf";
      // return await axios({
      //   url: pdfUrl,
      //   method: "GET",
      //   responseType: "blob",
      // });
    },
    async pinCertificate() {
      const blob = await this.downloadCertificate();
      const fixedBlob = await this.fixPdf(blob.data);
      await PinataService.pinFile(fixedBlob, "CERT " + this.form.name).then(
        (resp) => {
          this.form.certificate =
            process.env.VUE_APP_IPFS_GATEWAY + resp.ipfsHash;
          this.form.certificate_hash = resp.ipfsHash;
        }
      );
    },
    async pinJsonMetadata() {
      const description =
        "**Created by**: " +
        this.form.artist +
        "\n\n" +
        "**Year**: " +
        this.form.year +
        "\n\n" +
        "**Genre**: " +
        this.form.genre +
        "\n\n" +
        "**More details**: [artwork on V-Art website](https://v-art.digital/product/" +
        this.form.id +
        "/)\n\n" +
        "**IPFS**: [" +
        this.form.media_hash +
        "](https://vart.mypinata.cloud/ipfs/" +
        this.form.media_hash +
        ")\n\n" +
        "# Technical details \n" +
      "**Media**: " +
        this.form.genre +
        "\n\n" +
        "**Format**: " +
        this.form.medium +
        "\n\n" +
        "**Size**: " +
        this.form.size_art  +
        "\n\n" +
        "# Certificate\n" +
        "[External link to the Certificate](" +
        this.form.cretificateLink +
        ")\n\n" +
        "# Intellectual property statements" +
        "\n" +
        "**With NFT the following rights to the digital artwork are being transferred**:" +
        "\n" +
        "* adaptation to the format/size required for digital placement  on the platform/web resource/mobile application" +
        "\n" +
        "* storage of digital information, a file in a cloud data storage" +
        "\n" +
        "* placement and publication of NFT on the platform/web  resource/mobile application" +
        "\n" +
        "* publication of a digital object on the Internet in the public  domain" +
        "\n" +
        "* an entry in the metadata of the digital fingerprint of the file  for authentication" +
        "\n" +
        "* digital display (demonstration) of an object in online or virtual  galleries, AR/VR" +
        "\n" +
        "* use of NFT for personal purposes and further resale as NFT " +
        "\n" +
        "* publications in printed and electronic catalogs, advertising for the subsequent sale of the digital artwork";
      //       'Created by: Roman Minin

      // Year: 2022

      // Genre: Time-based media

      // More details: [artwork on V-Art website](https://v-art.digital/product/62aa0914d90173ab6e58a631/)

      // IPFS: [QmWbu6CbaFNtBR4qxzhJurG1FqsSkLgc8dSVNhWBwZuAY3](https://vart.mypinata.cloud/ipfs/QmWbu6CbaFNtBR4qxzhJurG1FqsSkLgc8dSVNhWBwZuAY3)

      // # Technical details
      // Media: Time-based media

      // Format: MOV

      // Size: 2048×2048

      // # Certificate

      // [External link to the Certificate](https://vart.page.link/EMe9)

      // # Intellectual property statements

      // With NFT the following rights to the digital artwork are being transferred:

      // * adaptation to the format/size required for digital placement  on the platform/web resource/mobile application
      // * storage of digital information, a file in a cloud data storage
      // * placement and publication of NFT on the platform/web  resource/mobile application
      // * publication of a digital object on the Internet in the public  domain
      // * an entry in the metadata of the digital fingerprint of the file  for authentication
      // * digital display (demonstration) of an object in online or virtual  galleries, AR/VR
      // * use of NFT for personal purposes and further resale as NFT
      // * publications in printed and electronic catalogs, advertising for the subsequent sale of the digital artwork'

      const metadata = {
        name: this.form.name,
        // description: this.form.description,
        description: description,
        image: "ipfs:/" + this.form.media_hash,
        external_url: "",
        animation_url: "ipfs:/" + this.form.media_preview_hash,
      };

      // const metadata = {
      //   name: this.form.name,
      //   description: this.form.description,
      //   image: "ipfs:/" + this.form.media_hash,
      //   externalUrl: "",
      //   animationUrl: this.form.media_preview,
      //   attributes: [],
      // };
      const resp = await PinataService.pinJson(
        metadata,
        "METADATA " + this.form.name
      );
      console.log("pin JSON metadata");
      console.log(resp);
      this.form.metadata = process.env.VUE_APP_IPFS_GATEWAY + resp.ipfsHash;
      this.form.metadata_hash = resp.ipfsHash;
      // const metadata = {
      //   name: this.form.id + ".json",
      //   product: this.form,
      //   certificate: this.certificate,
      // };
      // const resp = await PinataService.pinJson(
      //   metadata,
      //   "METADATA " + this.form.name
      // );
      // this.form.metadata = process.env.VUE_APP_IPFS_GATEWAY + resp.ipfsHash;
      // this.form.metadata_hash = resp.hexB64;
    },
    createMintData() {
      const metadata = {
        token_id: this.form.id,
        token_metadata: {
          title: this.form.name,
          description: this.form.description,
          media: this.form.media_preview,
          media_hash: this.form.media_preview_hash,
          copies: this.form.quantity, // !!!!!!!!!!!!!!!!!!!
          issued_at: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          expires_at: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          starts_at: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          updated_at: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          reference: this.form.metadata,
          reference_hash: this.form.metadata_hash,
        },
        token_royalties: this.packRoyalties(),
        price: this.form.price,
      };
      return metadata;
    },
    async mintToken() {
      if (!nearApi.isSignedIn()) {
        this.signIn();
      } else {
        await nearApi.mintToken(this.createMintData());
      }
    },
    createSellData() {
      const msg = {
        sale_conditions: {
          price: Number(
            parseFloat(this.form.price) * 1000000000000000000000000
          ).toLocaleString("fullwide", { useGrouping: false }),
          initial_payments: this.packArtists(),
        },
      };
      const data = {
        token_id: this.form.id,
        account_id: "",
        msg: JSON.stringify(msg),
      };
      return data;
    },
    sellToken() {
      console.log("selling");
      this.connectMetamask();
      this.connector.connection.subscribe(async ({ status, con }) => {
        if (status === "connected") {
          const sdk = createRaribleSdk(
            this.connection.connection.wallet,
            // "staging"
            this.environments.find((el) => el.name == process.env.VUE_APP_MODE)
              .raribleEnv
          );
          const sellResponse = await sdk.order.sell({
            itemId: toItemId(this.tokenId),
          });
        
        const sellOrderId = await sellResponse
          .submit({
            amount: 1,
            price: parseFloat(this.form.price),
            currency:   {
                "@type": "ETH",
                 blockchain: 'ETHEREUM',
        // contract: '0xf1138b40a8577b0190751ec678a0aba748bd0fa9',
        // tokenId: '38680856143571483902183662843577682341613493124839142288760118631909975654431',
              },
            originFees: this.generateCreators(),
            payouts: this.generateRoyalties(),
            //+1 hour
            expirationDate: new Date(Date.now() + 60 * 60 * 1000),
          })
          .then((resp) => {
            this.sendMessage("success", "Sell success", resp);
            
            
          })
          .catch((err) => {
            console.log(err);
            this.isMinting = false;
          });
          await vArtDigitalApi.bindToken(this.form.id, this.tokenId)
        }
      
       
      });
    },
    pathProduct() {},
    signIn() {
      nearApi.signInMarket();
    },
    signOut() {
      this.connectedAccount = null;
      nearApi.signOut();
    },
    async fixPdf(blob) {
      let p = new Promise((resolve, reject) => {
        var fileReader = new FileReader();
        fileReader.onload = function (event) {
          resolve(event.target.result);
        };
        fileReader.onerror = reject;
        fileReader.readAsArrayBuffer(blob);
      });
      const arr = await p;
      const uint8array = new Uint8Array(arr);
      const pdfTriggerIndex = this.findPdfTrigger(uint8array);
      const arr2 = arr.slice(pdfTriggerIndex);
      return new Blob([arr2], { type: "application/pdf" });
    },
    findPdfTrigger(array) {
      var result = -1;
      const pdfTrigger = new Uint8Array([37, 80, 68, 70, 45, 49, 46, 55]);
      const arrEquals = (a, b) =>
        a.length === b.length && a.every((v, i) => v === b[i]);
      array.forEach((e, index) => {
        if (e == pdfTrigger[0] && result == -1) {
          const tmpArray = array.slice(index, index + pdfTrigger.length);
          if (arrEquals(pdfTrigger, tmpArray)) {
            result = index;
          }
        }
      });
      return result;
    },
  },
};
</script>

