const Ajv = require("ajv")
const ajv = new Ajv()
import axios from "axios"
import moment from "moment"
const FormData = require('form-data');

const certPrefix = process.env.VUE_APP_CERTIFICATES_PROXY_PREFIX
const certHost = process.env.VUE_APP_CERTIFICATES_HOST
const certApiUrl = process.env.VUE_APP_CERTIFICATES_API_URL
const certDownloadUrl = process.env.VUE_APP_CERTIFICATES_DOWNLOAD_URL

const schema = {
    type: "object",
    properties: {
        preview_image: { type: "string" },
        author: { type: "string" },
        name: { type: "string" },
        year: { type: "string" },
        edition: { type: "string" },
        quantity: { type: "integer" },
        url: { type: "string" },
        currency: { enum: ["ethereum", "near"] },
        contract: { type: "string" },
        genre: { type: "string" },
        dimensions: { type: "string" },
        slug_link: { type: "string" },
        copyrights: {
            type: "array",
            items: {
                type: "string",
                enum: [
                    "adaption",
                    "storage",
                    "placement",
                    "publication",
                    "metadata",
                    "demonstration",
                    "personal_use",
                    "advertising"
                ]
            }

        },
        token_id: { type: "string" },
        creation_date: { type: "string" },
    },
    required: ["preview_image", "author", "name", "year", "edition", "quantity", "url",
        "currency", "contract", "genre", "dimensions", "slug_link", "copyrights",
        "token_id", "creation_date"
    ],
    additionalProperties: false
}



const validate = ajv.compile(schema)

class CertificatesApi {

    async downloadCertificate(certificateId) {
        const pdfUrl = certHost + "/" + certDownloadUrl + '/' + certificateId + "/certificate.pdf";
        const proxyPdfUrl = pdfUrl.replaceAll(certHost, certPrefix)
        return await axios({
            url: proxyPdfUrl,
            method: "GET",
            responseType: "blob",
        });

    }

    async createCertificate(metadata) {
        let certResolve, certReject;
        let certCreate = new Promise(function (resolve, reject) {
            certResolve = resolve;
            certReject = reject;
        });

        let data = new FormData()
        data.append('preview_image', metadata.preview_image)
        data.append('name', metadata.name),
        data.append('author', metadata.author)
        data.append('name', metadata.name)
        data.append('year', metadata.year)
        data.append('edition', metadata.edition)
        data.append('quantity', metadata.quantity)
        data.append('url', metadata.url)
        data.append('currency', metadata.currency)
        data.append('contract', metadata.contract)
        data.append('genre', metadata.genre)
        data.append('dimensions', metadata.dimensions)
        data.append('slug_link', metadata.slug_link)
        metadata.copyrights.forEach((c, i) => {
            data.append('copyrights[' + i + ']', c)
        })
        data.append('token_id', metadata.token_id)
        data.append('creation_date', metadata.creation_date)
console.log('data');
console.log(data);
console.log(certPrefix + '/' + certApiUrl);
        await axios.post(    
                 
            'https://certificate.v-art.digital' + '/' + certApiUrl
            , data, {
            maxBodyLength: 'Infinity',
            headers: {
                'Accept': 'application/json',
                'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
            }
        }
        )
            .then(resp => {
                certResolve(resp.data)
            })
            .catch(err => {
                certReject(err)
            })
        await certCreate
        return certCreate

    }

    validateData(metadata) {
        const cd = moment(metadata.creation_date, 'DD/MM/YYYY HH:mm:ss')
        if (cd.isValid() && validate(metadata)) {
            return true
        } else {
            return false
        }
    }



}

export default new CertificatesApi()