<template>
  <div class="near-add-wallet-page">
    <div class="near-add-wallet__container container">
      <div class="near-add-wallet__header">Add NEAR wallet</div>
      <div class="near-add-wallet__logo">
        <svg
          width="60"
          height="60"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.4475 0.91375L10.685 6.5C10.5742 6.66637 10.6032 6.889 10.7529 7.02141C10.9027 7.15382 11.1272 7.15535 11.2787 7.025L14.9825 3.815C15.0269 3.77532 15.0905 3.76561 15.1446 3.79026C15.1988 3.81491 15.2333 3.86924 15.2325 3.92875V13.9862C15.2325 14.0493 15.193 14.1056 15.1337 14.1272C15.0744 14.1487 15.008 14.1309 14.9675 14.0825L3.7725 0.67875C3.40804 0.248383 2.87271 0.000144382 2.30875 6.65001e-08H1.9175C0.858494 6.65001e-08 0 0.858494 0 1.9175V16.0825C0 17.1415 0.858494 18 1.9175 18C2.58424 18.0002 3.20327 17.6542 3.5525 17.0863L7.315 11.5C7.42582 11.3336 7.3968 11.111 7.24705 10.9786C7.0973 10.8462 6.87279 10.8446 6.72125 10.975L3.0175 14.185C2.97315 14.2247 2.90953 14.2344 2.85537 14.2097C2.8012 14.1851 2.76672 14.1308 2.7675 14.0712V4.01375C2.76752 3.95067 2.80701 3.89435 2.86629 3.87282C2.92558 3.85128 2.992 3.86914 3.0325 3.9175L14.2262 17.3212C14.591 17.7519 15.1269 18.0002 15.6912 18H16.0825C17.1415 18 18 17.1415 18 16.0825V1.9175C18 0.858494 17.1415 6.65001e-08 16.0825 6.65001e-08C15.4158 -0.000175118 14.7967 0.345783 14.4475 0.91375Z"
            fill="#FF0090"
          />
        </svg>
      </div>
      <div class="near-add-wallet__account">
         {{
          connectedAccount.length > 25
            ? connectedAccount.substring(0, 10) + "..." +  connectedAccount.substring(connectedAccount.length-10, connectedAccount.length)
            : connectedAccount
        }}
      </div>
      <div class="near-add-wallet__actions">
        <button class="btn btn--light--pink" @click="signIn()">
          {{ connectedAccount ? "Change wallet" : "Sign in" }}
        </button>
        <button
          v-show="connectedAccount"
          :disabled="!connectedAccount"
          class="btn btn--light--pink"
          @click="save()"
        >
          Save
        </button>
      </div>
    </div>
  </div>
  <message-dialog
    :visible="message.show"
    :closable="message.closable"
    :type="message.type"
    :title="message.title"
    :message="message.message"
    @close="message.show = false"
  />
</template>

<script>
import vArtDigitalApi from "@/services/v-art-digital-api";
import MessageDialog from "../components/MessageDialog.vue";
import nearApi from "../services/near-api";
export default {
  components: {
    MessageDialog,
  },
  data() {
    return {
      vArtApiToken: null,
      connectedAccount: null,
      message: {
        show: false,
        closable: true,
        type: "success",
        title: "Title",
        message: "Message",
      },
    };
  },
  async created() {
    // Checking API Token
    this.vArtApiToken = this.$route.query.vArtApiToken;
    if (!this.vArtApiToken) {
      this.message.closable = false;
      this.message.title = "Parameters error";
      this.message.message = "Please check Api token in query parameters!";
      this.message.show = true;
    } else {
      vArtDigitalApi.setApiToken(this.vArtApiToken);
      await nearApi.init();
      this.connectedAccount = await nearApi.getAccount();
    }
  },
  methods: {
    signIn() {
      nearApi.signInMarket();
    },
    async save() {
      await vArtDigitalApi
        .addWallet(this.connectedAccount)
        .then((resp) => {
          this.message.closable = false;
          this.message.title = "Wallet has been attached!";
          this.message.message = "You can close this tab";
          this.message.show = true;
        })
        .catch((err) => {
          this.message.closable = false;
          this.message.title = "Saving error!";
          this.message.message = "Please try again";
          this.message.show = true;
        });
    },
  },
};
</script>

<style>
</style>