<template>
  <div class="near-buy-page">
    <div class="near-buy-page__container container">
      <div class="near-buy-page__logo">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="85"
          height="102"
          viewBox="0 0 85 102"
          fill="none"
        >
          <path
            d="M23 2H42.5C53.2696 2 62 10.7304 62 21.5V50"
            stroke="white"
            fill="none"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M62 2H42.5C31.7304 2 23 10.7304 23 21.5V50"
            stroke="white"
            fill="none"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2 27H83V81C83 91.4934 74.4934 100 64 100H21C10.5066 100 2 91.4934 2 81V27Z"
            stroke="white"
            fill="none"
            stroke-width="4"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="near-buy-page__header">Burn token</div>
      <div class="near-buy-page__name" v-if="this.tokenId">
        Token ID : {{ this.tokenId }}
      </div>
      <div class="near-buy-page__near-logo">
        <svg
          width="40"
          height="40"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.4475 0.91375L10.685 6.5C10.5742 6.66637 10.6032 6.889 10.7529 7.02141C10.9027 7.15382 11.1272 7.15535 11.2787 7.025L14.9825 3.815C15.0269 3.77532 15.0905 3.76561 15.1446 3.79026C15.1988 3.81491 15.2333 3.86924 15.2325 3.92875V13.9862C15.2325 14.0493 15.193 14.1056 15.1337 14.1272C15.0744 14.1487 15.008 14.1309 14.9675 14.0825L3.7725 0.67875C3.40804 0.248383 2.87271 0.000144382 2.30875 6.65001e-08H1.9175C0.858494 6.65001e-08 0 0.858494 0 1.9175V16.0825C0 17.1415 0.858494 18 1.9175 18C2.58424 18.0002 3.20327 17.6542 3.5525 17.0863L7.315 11.5C7.42582 11.3336 7.3968 11.111 7.24705 10.9786C7.0973 10.8462 6.87279 10.8446 6.72125 10.975L3.0175 14.185C2.97315 14.2247 2.90953 14.2344 2.85537 14.2097C2.8012 14.1851 2.76672 14.1308 2.7675 14.0712V4.01375C2.76752 3.95067 2.80701 3.89435 2.86629 3.87282C2.92558 3.85128 2.992 3.86914 3.0325 3.9175L14.2262 17.3212C14.591 17.7519 15.1269 18.0002 15.6912 18H16.0825C17.1415 18 18 17.1415 18 16.0825V1.9175C18 0.858494 17.1415 6.65001e-08 16.0825 6.65001e-08C15.4158 -0.000175118 14.7967 0.345783 14.4475 0.91375Z"
            fill="#FF0090"
          />
        </svg>
      </div>
      <div class="near-buy-page__account" v-show="connectedAccount">
        (connected to: {{ connectedAccount }}
        )
      </div>
      <div class="near-buy-page__actions">
        <button class="btn btn--dark--white" @click="burn()"  v-show="connectedAccount">
          Burn
        </button>
         <button
          v-show="!connectedAccount"
          class="btn btn--dark--white--transparent"
          @click="signIn()"
        >
          Sign in
        </button>
        <button
          v-show="connectedAccount"
          class="btn btn--dark--white--transparent"
          @click="signOut()"
        >
          Sign out
        </button>
      </div>
    </div>
    <message-dialog
      :visible="message.show"
      :closable="message.closable"
      :type="message.type"
      :title="message.title"
      :message="message.message"
      @close="message.show = false"
    />
  </div>
</template>

<script>
import vArtDigitalApi from "@/services/v-art-digital-api";
import MessageDialog from "../components/MessageDialog.vue";
import nearApi from "../services/near-api";
export default {
  components: {
    MessageDialog,
  },
  data() {
    return {
      tokenId: null,
      connectedAccount: null,

      message: {
        show: false,
        closable: false,
        type: "success",
        title: "Title",
        message: "Message",
      },
    };
  },
  async created() {
    // // Checking API Token
    // this.vArtApiToken = this.$route.query.vArtApiToken;
    // if (!this.vArtApiToken) {
    //   this.message.closable = false;
    //   this.message.title = "Parameters error";
    //   this.message.message = "Please check Api token in query parameters!";
    //   this.message.show = true;
    // } else {
    //   vArtDigitalApi.setApiToken(this.vArtApiToken);
    // }

    this.tokenId = this.$route.query.tokenId;

    if (!this.tokenId) {
      this.message.type = "error";
      this.message.title = "Token ID error";
      this.message.closable = false;
      this.message.message = "Please check token ID in url!";
      this.message.show = true;
      return;
    }

    // this.tx = this.$route.query.transactionHashes;
    //  const href = window.location.href

    // if (this.tx || href.includes('transactionHashes')) {
    //   await vArtDigitalApi
    //       .patchProduct(this.tokenId, this.buyerId)
    //       .catch((err) => {
    //         this.message.type = "error";
    //         this.message.title = "Error changing owner";
    //         this.message.closable = false;
    //         this.message.message = "Error while changing owner on v-art API";
    //         this.message.show = true;
    //       });
    //   this.message.type = "success";
    //   this.message.title = "Success";
    //   this.message.closable = false;
    //   this.message.message =
    //     "Transaction successfully done! You can close this page. Transaction hash: " +
    //     this.tx;
    //   this.message.show = true;
    //   return;
    // }

    // this.buyerId = this.$route.query.buyerId;
    // if (!this.buyerId) {
    //   this.message.type = "error";
    //   this.message.title = "Buyer ID error";
    //   this.message.closable = false;
    //   this.message.message = "Please check buyer ID in url!";
    //   this.message.show = true;
    //   return;
    // }

    await nearApi.init();
    if (nearApi.isSignedIn()) {
      this.connectedAccount = await nearApi.getAccount();
      //   const price = await nearApi.getNftPrice(this.tokenId);
      //   if (!price) {
      //     this.message.type = "error";
      //     this.message.title = "Token error";
      //     this.message.closable = true;
      //     this.message.message = "Seems like this token not in offers!";
      //     this.message.show = true;
      //   } else {
      //     const nprice = Number(
      //       parseFloat(price) / 1000000000000000000000000
      //     ).toLocaleString();
      //     this.price = nprice;
      //   }
    } else {
      this.signIn();
    }
  },
  methods: {
    signOut() {
      this.connectedAccount = null;
      nearApi.signOut();
    },
    signIn() {
      nearApi.signInMarket();
    },
    async burn() {
      if (!nearApi.isSignedIn()) {
        console.log("No connected account");
      } else {
        await nearApi.burn(this.tokenId)
        // .then(resp => {
        //      this.message.type = "success";
        //   this.message.title = "Token unlisted";
        //   this.message.closable = false;
        //   this.message.message = "Token successfull unlisted!";
        //   this.message.show = true;
        // })
        // .catch(err => {
        //     console.log('I CATCH!!');
        //            this.message.type = "error";
        //   this.message.title = "Unlisting error";
        //   this.message.closable = false;
        //   this.message.message = err;
        //   this.message.show = true;
        // })
      }
    },
  },
};
</script>

<style>
</style>