import axios from 'axios'

// const baseUrl = 'http://159.69.192.185/v-art-digital/public/index.php/api/assets/'
// const apiUrl = 'https://young-journey-73255.herokuapp.com/development/api/v1/'
const apiUrl = 'https://prodback.v-art.digital:3000/production/api/v1/'

// https://young-journey-73255.herokuapp.com/development/api/v1/user/j7z6w8n7MbOpB6wRHiI1TVhpzcD3


var apiToken = ''

// const headers = {
//     'Accept': 'application/json'
// }

class vArtDigitalAPI {

    setApiToken(token) {
        this.apiToken = token
    }

    async getProductById(id) {
        // console.log(apiToken)
        // console.log(productsUrl + '/' + id)
        return axios.get(apiUrl + 'product/' + id, {
            headers: {
                Accept: 'accept: */*',
                Authorization: 'Bearer ' + apiToken
            }
        })
        // .then(resp => { resp })
    }

    async getUserById(id) {
        return axios.get(apiUrl + 'user/' + id, {
            headers: {
                Accept: 'accept: */*',
                Authorization: 'Bearer ' + apiToken
            }
        })
    }

    //https://young-journey-73255.herokuapp.com/development/api/v1/
    //admin/product/approve/62781b40277b69f433431954?approved=true&tokenId=62781b40277b69f433431954'


    // https://young-journey-73255.herokuapp.com/development/api/v1/
    // admin/product/approve/62782195277b69f4334319a2?approved=true&tokenId=62782195277b69f4334319a2


    // https://young-journey-73255.herokuapp.com/development/api/v1/
    // admin/product/approve/62782195277b69f4334319a2?approved=true&tokenId=62782195277b69f4334319a2


    // https://young-journey-73255.herokuapp.com/development/api/v1/
    // admin/product/approve/62782195277b69f4334319a2?approved=false&tokenId=62782195277b69f4334319a2

    // https://young-journey-73255.herokuapp.com/development/api/v1/
    // admin/product/approve/62782195277b69f4334319a2?approved=false&tokenId=62782195277b69f4334319a2

    async approveProduct(productId) {
        const url = apiUrl + 'admin/product/approve/' + productId + '?approved=true&tokenId=' + productId;
        return axios({
            method: 'patch',
            url: url,
            headers: {
                Accept: 'accept: */*',
                Authorization: 'Bearer ' + this.apiToken
            }
        })
    }
    //https://young-journey-73255.herokuapp.com/development/api/v1
    //product/62781b40277b69f433431955?owner=ZAGl8vj2adNH40g8YmQ2LsRFWXD3' \
    async patchProduct(productId, newOwner) {
        const url = apiUrl + 'product/' + productId + '?owner=' + newOwner
        return axios({
            method: 'patch',
            url: url,
            headers: {
                Accept: 'accept: */*',
                Authorization: 'Bearer ' + this.apiToken
            }
        })
    }

    async addWallet(wallet) {
        const data = { "type":"Near", "walletAddress": wallet }
        return axios({
            method: 'post',
            url: 'https://prodback.v-art.digital:3000/production/api/v1/wallet',
            headers: {
                Accept: 'accept: */*',
                Authorization: 'Bearer ' + this.apiToken,
                ContentType: 'application/json'
            },
            data
        })
    }

    // async addWallet(wallet) {
    //     const data = { "profileType": { "walletAddress": wallet } }
    //     return axios({
    //         method: 'patch',
    //         url: 'https://young-journey-73255.herokuapp.com/development/api/v1/user',
    //         headers: {
    //             Accept: 'accept: */*',
    //             Authorization: 'Bearer ' + this.apiToken,
    //             ContentType: 'application/json'
    //         },
    //         data
    //     })
    // }

    // async holdAsset(assetId) {
    //     await axios.post(baseUrl + assetId + '/hold', null, { headers: headers })

    // }

    // async unholdAsset(assetId) {
    //     await axios.post(baseUrl + assetId + '/unhold', {}, { headers: headers })

    // }

    // async buyAsset(assetId) {
    //     await axios.post(baseUrl + assetId + '/buy', {}, { headers: headers })
    // }

    // async getNearMintMetadata(productId) {
    //     return new Promise((resolve, reject) => {
    //         let wait = setTimeout(() => {
    //             clearTimeout(wait)
    //             const example = {
    //                 // token_id: "123456789",
    //                 // title: "token_test",
    //                 // description: "Very much",
    //                 // media: "https://ipfs.io/ipfs/bafybeicmy2c2hpfb5so5maacqnfuxm54ohom5iaeaeeuhvrxg6j32hn6re/avatars-rfwy5mSeUytFz5Gx-8s06ZQ-t240x240.jpg",
    //                 // media_hash: "cHVuayBmb3IgbWpvbG5lYXIhIG1vbSBpIGxvdmUgeW91",
    //                 // copies: 1,
    //                 // issued_at: "2020-07-10 15:00:00.000", //date now
    //                 // expires_at: "2020-07-10 15:00:00.000",
    //                 // starts_at: "2020-07-10 15:00:00.000",
    //                 // updated_at: "2020-07-10 15:00:00.000",
    //                 // reference: "https://myjsonwithtraits.com/punk.json", //url to pinata with cert
    //                 // reference_hash: "cHVuayBmb3IgbWpvbG5lYXIhIG1vbSBpIGxvdmUgeW91",
    //                 // token_royalties: { "vartdev.testnet": 5, "new_rolal": 7 },
    //                 // deposit: "9570000000000000000000",
    //                 // gas: "300000000000000",
    //                 // sellPrice: "1000000000000000000000000",
    //                 // artist_id: "belka23.testnet",
    //                 // token_royalties: [],
    //                 // deposit: "9570000000000000000000",
    //                 // gas: "300000000000000",
    //                 // sellPrice: "1"
    //             }
    //             resolve(example)
    //         }, 1000)

    //     })
    // }

    // async getProducts() {
    //     return axios.get(productsUrl, {
    //         headers: {
    //             Accept: 'accept: */*',
    //             Authorization: 'Bearer ' + vArtApiToken
    //         }
    //     })
    // }

    // async getProductById(id) {

    // }
    async bindToken(productId, tokenId) {
        // console.log(productId)
        // console.log(tokenId)
        let tid = tokenId
        if(tokenId.startsWith('ETHEREUM:')){
            tid = tokenId.replaceAll('ETHEREUM:', '')
        }
        var FormData = require('form-data');
        var data = new FormData();
        let shortTokenId = tokenId.replaceAll('ETHEREUM:', '')
        data.append('asset_id', productId);
        data.append('token', shortTokenId);


        var config = {
            method: 'post',
            url: 'http://159.69.192.185/v-art-digital/public/index.php/api/assets/bind',
            headers: {
                'Accept': 'application/json'
            },
            data: data
        };

        return axios(config)
        

    }


}

export default new vArtDigitalAPI()