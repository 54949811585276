<template>
    <div v-show="show" v-bind:class="closable? 'msg-block' : 'msg-fullscreen'">
        <div class="msg-container">
            <div class="msg-title">
                {{title}}
            </div>
            <div class="msg-message">
                {{message}}
            </div>
            <div class="msg-close" v-if="closable">
                <button class="btn btn--light--pink" @click="show = false">OK</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    type: String,
    title: String,
    message: String,
    visible: Boolean,
    closable: Boolean,
  },
  
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
}
</script>

