import { createStore } from 'vuex'

export default createStore({
  modules: {},
  state: {
      moralisServerUrl: 'https://tigzvkr3qprg.usemoralis.com:2053/server',
      moralisAppId: 'RKy1ZZ25aSdSLXfUPnTtNjQwPHyvUmwXdQXxc4gw',
      blockchain: 'rinkeby',
      openseaNet: 'testnet',
      tokenId: null,
      sku: null,
      tokenAddress: null,
      asset: null,
      accountAddress: null,
      accountBalance: null,
      order: null,
      orderId: null,
      sdk: null,
  },
  getters: {
      MORALIS_SERVER_URL: state => {
          return state.moralisServerUrl
      },
      MORALIS_APP_ID: state => {
          return state.moralisAppId
      },
      BLOCKCHAIN: state => {
          return state.blockchain
      },
      OPENSEA_NET: state => {
          return state.openseaNet
      },
      TOKEN_ID: state => {
          return state.tokenId
      },
      TOKEN_ADDRESS: state => {
          return state.tokenAddress
      },
      SKU: state => {
          return state.sku
      },
      ASSET: state => {
          return state.asset
      },
      ACCOUNT_ADDRESS: state => {
          return state.accountAddress
      },
      ACCOUNT_BALANCE: state => {
          return state.accountBalance
      },
      ORDER: state => {
          return state.order
      },
      ORDER_ID: state => {
          return state.orderId
      },
      ACCOUNT_BALANCE_ETH: state => {
          return state.accountBalance
      },
      PRICE_ETH: state => {
          // return state.asset.orders[0].currentPrice / 1000000000000000000;
          return state.order.makePrice
      },
      PRICE_USD: (state, getters) => {
          // return state.asset.orders[0].paymentTokenContract.usdPrice *
          // getters.PRICE_ETH
          return state.order.makePriceUsd
      },
      PRICE_USD_PRETTY: (state, getters) => {
          return (state.asset.orders[0].paymentTokenContract.usdPrice *
              getters.PRICE_ETH).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      DATA_LOADED: (state) => {
          if (
              state.tokenId != null &&
              state.tokenAddress != null &&
              state.accountAddress != null &&
              state.accountBalance != null &&
              state.asset != null &&
              state.order != null
          ) {
              return true
          } else {
              return false
          }
      },
      SDK: (state) => {
          return state.sdk
      }
  },
  mutations: {
      TOKEN_ID: (state, payload) => {
          state.tokenId = payload
      },
      TOKEN_ADDRESS: (state, payload) => {
          state.tokenAddress = payload
      },
      SKU: (state, payload) => {
          state.sku = payload
      },
      ASSET: (state, payload) => {
          state.asset = payload
      },
      ACCOUNT_ADDRESS: (state, payload) => {
          state.accountAddress = payload
      },
      ACCOUNT_BALANCE: (state, payload) => {
          state.accountBalance = payload
      },
      ORDER: (state, payload) => {
          state.order = payload
      },
      ORDER_ID: (state, payload) => {
          state.orderId = payload
      },
      SDK: (state, payload) => {
          state.sdk = payload
      }
  },
  actions: {},
})
